import React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

import './styles.scss';

export default function Copyright() {
    return (
        <Typography className="copyright" variant="body2" align="center">
            {"Copyright © "}
            <Link
                color="inherit"
                component={RouterLink}
                to="/"
                className="copyright-link"
                title="Visit Toggles for Outlook - Email Productivity Tool"
            >
                Toggles
            </Link>{" "}
            {new Date().getFullYear()} |{" "}
            <Link
                component={RouterLink}
                to="/terms"
                underline="none"
                color="inherit"
                className="copyright-link"
                title="View Toggles Terms of Service"
            >
                Terms of Service
            </Link>{" "}
            |{" "}
            <Link
                component={RouterLink}
                to="/privacy"
                underline="none"
                color="inherit"
                className="copyright-link"
                title="Read Toggles Privacy Policy"
            >
                Privacy Policy
            </Link>
        </Typography>
    );
}
