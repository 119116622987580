// @ts-nocheck
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import Link from "@mui/material/Link";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StandardPriceCard from "../components/Pricing/StandardPriceCard";
import PremiumPriceCard from "../components/Pricing/PremiumPriceCard";
import BusinessProPriceCard from "../components/Pricing/BusinessProPriceCard";
import ComparisonTable from "../components/Pricing/ComparisonTable";
import solutionsCtaGraphic from "../assets/solutions-cs-cta.png";

import "./Pricing.scss";

export default function Pricing() {
    const [interval, setInterval] = useState("annual");

    const handleIntervalChange = (event, newInterval) => {
        setInterval(newInterval);
    };

    return (
        <Container maxWidth="xl" className="pricing-container">
            <Metadata />

            <div className="pricing-hero">
                <Box className="hero-box">
                    <Typography
                        className="hero-title animated-charcter"
                        variant="h1"
                        component={"h1"}
                        align="center"
                    >
                        Ready to accomplish more — faster?
                    </Typography>
                    <Typography
                        className="hero-subtitle"
                        variant="h4"
                        component={"h2"}
                        align="center"
                    >
                        Try Toggles for free and find your perfect plan
                    </Typography>
                    <div className="hero-buttons">
                        <Button
                            className="hero-btn"
                            variant="outlined"
                            size="large"
                            href="https://hub.togglesforemail.com/signup?type=premium"
                        >
                            Start free trial
                        </Button>
                        <Button
                            className="hero-btn"
                            variant="contained"
                            size="large"
                            href="/request-demo"
                        >
                            Schedule a demo
                        </Button>
                    </div>
                </Box>
            </div>

            <div className="pricing-interval">
                <ToggleButtonGroup
                    value={interval}
                    exclusive
                    onChange={handleIntervalChange}
                    aria-label="Payment Interval"
                >
                    <ToggleButton
                        className={`interval-selection ${
                            interval === "month" ? "selected" : ""
                        }`}
                        value="month"
                        aria-label="monthly"
                    >
                        Monthly Billing
                    </ToggleButton>
                    <ToggleButton
                        className={`interval-selection ${
                            interval === "annual" ? "selected" : ""
                        }`}
                        value="annual"
                        aria-label="annually"
                    >
                        Yearly Billing (Save 20%)
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>

            <Container maxWidth="xl" className="price-cards-container">
                <Grid container spacing={2}>
                    <Grid xs={12} md={4}>
                        <StandardPriceCard showButton={false} />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <PremiumPriceCard
                            showButton={false}
                            interval={interval}
                        />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <BusinessProPriceCard
                            showButton={false}
                            interval={interval}
                        />
                    </Grid>
                </Grid>
            </Container>

            <Container
                maxWidth="md"
                className="comparison-table-container"
                sx={{ display: { xs: "none", md: "block" } }}
            >
                <ComparisonTable />
            </Container>

            <div className="split-cta full-width light reverse">
                <Container maxWidth="lg">
                    <Grid container>
                        <Grid xs={12} md={4} className="graphic-container">
                            <img
                                src={solutionsCtaGraphic}
                                alt="Toggles for Outlook - Human Resources Graphic"
                            />
                        </Grid>
                        <Grid xs={12} md={8} className="text-content">
                            <Typography
                                className="text-title mb-30"
                                variant="h3"
                            >
                                Transform the way your team communicates
                            </Typography>
                            <Typography
                                className="text-description"
                                variant="body1"
                            >
                                Whether you're in Sales, Marketing, Customer
                                Support, or any other team, Toggles empowers you
                                to streamline workflows, automate repetitive
                                tasks, and save time on email communications —
                                so your team can focus on what truly matters:
                                delivering results.
                            </Typography>
                            <div className="cta-buttons">
                                <Button
                                    className="cta-button"
                                    variant="outlined"
                                    size="large"
                                    href="https://hub.togglesforemail.com/signup?type=business"
                                >
                                    Try for free
                                </Button>
                                <Button
                                    className="cta-button demo-request"
                                    variant="outlined"
                                    size="large"
                                    href="/request-demo"
                                >
                                    Get a demo
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <Container maxWidth="lg">
                <div className="faq-container">
                    <Typography
                        className="faq-header"
                        variant="h3"
                        component="h2"
                        gutterBottom
                    >
                        Frequently asked questions
                    </Typography>

                    <Accordion className="faq-accordion">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="faq-1-content"
                            id="faq-1-header"
                        >
                            <Typography variant="h6">
                                What is included in the free trial?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                The free trial gives you full access to Toggles'
                                Business Pro features for 14 days or Toggles'
                                Premium Plus features for 7 days, including
                                email templates and signatures, automation
                                workflows, and team collaboration tools (with
                                Business Pro). No credit card is required to
                                start.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className="faq-accordion">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="faq-2-content"
                            id="faq-2-header"
                        >
                            <Typography variant="h6">
                                Do I need to sign a contract?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                No long-term contracts are required. You can
                                choose between monthly or annual billing, and
                                you're free to cancel anytime.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className="faq-accordion">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="faq-3-content"
                            id="faq-3-header"
                        >
                            <Typography variant="h6">
                                How does team pricing work?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                The Business Pro plan is priced at $19 per user
                                per month, or $180 per user per year (~20%
                                discount), with a 5-seat minimum. This ensures
                                your team has access to all premium features
                                like shared templates and streamlined workflows.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className="faq-accordion">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="faq-4-content"
                            id="faq-4-header"
                        >
                            <Typography variant="h6">
                                How does Toggles save me time?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Toggles reduces the time spent on repetitive
                                email tasks by automating workflows, inserting
                                prebuilt templates and signatures, and managing
                                team collaboration — all from your email
                                interface.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className="faq-accordion">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="faq-5-content"
                            id="faq-5-header"
                        >
                            <Typography variant="h6">
                                Is my data secure with Toggles?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Yes, your data is stored securely. We use
                                industry-standard practices to ensure the
                                privacy and security of your information.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className="faq-accordion">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="faq-6-content"
                            id="faq-6-header"
                        >
                            <Typography variant="h6">
                                What happens after the free trial ends?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                After your free trial, you can choose to
                                subscribe to a paid plan to continue using
                                Toggles by entering a payment method. If you
                                don't add a payment method, your account will
                                revert to a limited free version.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className="faq-accordion">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="faq-7-content"
                            id="faq-7-header"
                        >
                            <Typography variant="h6">
                                How can I upgrade or downgrade my plan?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                You can easily upgrade or downgrade your plan
                                from your account settings in the Hub. Changes
                                will take effect at the start of your next
                                billing cycle.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className="faq-accordion">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="faq-8-content"
                            id="faq-8-header"
                        >
                            <Typography variant="h6">
                                Can I pay for Toggles monthly instead of
                                annually?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Yes, we offer both monthly and annual payment
                                options. Choosing the annual plan gives you a
                                discount compared to monthly payments.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className="faq-accordion">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="faq-9-content"
                            id="faq-9-header"
                        >
                            <Typography variant="h6">
                                Does Toggles work with all email providers?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Currently, Toggles is only available for
                                Outlook. We are working on expanding to Gmail
                                soon.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className="faq-accordion">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="faq-10-content"
                            id="faq-10-header"
                        >
                            <Typography variant="h6">
                                How do I calculate savings using this tool?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                The{" "}
                                <Link
                                    href="/productivity-calculator"
                                    underline="none"
                                    title="Use our ROI Calculator to calculate email productivity with Toggles"
                                >
                                    ROI Calculator
                                </Link>{" "}
                                estimates the time saved and productivity
                                benefits for your team by using Toggles. Simply
                                input the number of team members, their average
                                salary, and the time saved each week by using
                                Toggles, and the tool will calculate the
                                projected annual ROI and savings.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </Container>

            <div className="section full-width cta-curved">
                <Typography
                    className="section-title"
                    variant="h2"
                    align="center"
                >
                    See all you can accomplish with Toggles
                </Typography>
                <div className="cta-buttons">
                    <Button
                        className="cta-btn contained"
                        variant="outlined"
                        size="large"
                        href="https://hub.togglesforemail.com/signup?type=premium"
                    >
                        Get Started
                    </Button>
                    <Button
                        className="cta-btn outlined"
                        variant="outlined"
                        size="large"
                        href="/request-demo"
                    >
                        Talk to sales
                    </Button>
                </div>
            </div>
        </Container>
    );
}

function Metadata() {
    return (
        <Helmet>
            <title>
                Toggles | Email Productivity Tool Pricing and Plan Details
            </title>
            <meta
                name="description"
                content="Discover the pricing options for Toggles. Choose from premium features including unlimited rules, advanced logic, and premium action types. Find the perfect plan for your organization."
            />
            <meta property="og:url" content="https://www.gettoggles.com" />
            <meta property="og:type" content="website" />
            <meta
                property="og:title"
                content="Toggles | Email Productivity Tool Pricing and Plan Details"
            />
            <meta
                property="og:description"
                content="Discover the pricing options for Toggles. Choose from premium features including unlimited rules, advanced logic, and premium action types. Find the perfect plan for your organization."
            />
            <meta
                property="og:image"
                content="https://www.gettoggles.com/graphics/meta-fb-image-1200x630.jpg"
            />
            <meta name="twitter:site" content="@togglesforemail" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="twitter:title"
                content="Toggles | Email Productivity Tool Pricing and Plan Details"
            />
            <meta
                name="twitter:description"
                content="Discover the pricing options for Toggles. Choose from premium features including unlimited rules, advanced logic, and premium action types. Find the perfect plan for your organization."
            />
            <meta
                name="twitter:image"
                content="https://www.gettoggles.com/graphics/meta-twitter-image-1600x900.jpg"
            />
            <link rel="canonical" href="https://www.gettoggles.com/pricing" />

            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "Product",
                        "name": "Toggles Email Productivity Tool",
                        "description": "Explore Toggles subscription plans, including Starter, Premium Plus, and Business Pro, to boost email productivity and streamline team collaboration.",
                        "url": "https://www.gettoggles.com/pricing",
                        "image": "https://www.gettoggles.com/graphics/meta-fb-image-1200x630.jpg",
                        "offers": [
                            {
                                "@type": "Offer",
                                "url": "https://www.gettoggles.com/pricing",
                                "priceCurrency": "USD",
                                "price": "0.00",
                                "priceValidUntil": "2025-12-31",
                                "name": "Starter Plan",
                                "description": "Free plan with limited functionality for individuals just getting started with email automation.",
                                "eligibleRegion": "US"
                            },
                            {
                                "@type": "Offer",
                                "url": "https://www.gettoggles.com/pricing",
                                "priceCurrency": "USD",
                                "price": "12.50",
                                "priceValidUntil": "2025-12-31",
                                "name": "Premium Plus (Monthly)",
                                "description": "Monthly plan for individuals with full access to premium features and email automation tools.",
                                "eligibleRegion": "US",
                                "priceSpecification": {
                                    "@type": "PriceSpecification",
                                    "priceCurrency": "USD",
                                    "price": "12.50",
                                    "priceType": "Subscription",
                                    "billingDuration": "P1M"
                                }
                            },
                            {
                                "@type": "Offer",
                                "url": "https://www.gettoggles.com/pricing",
                                "priceCurrency": "USD",
                                "price": "120.00",
                                "priceValidUntil": "2025-12-31",
                                "name": "Premium Plus (Annual)",
                                "description": "Annual plan for individuals offering savings and access to advanced email automation tools.",
                                "eligibleRegion": "US",
                                "priceSpecification": {
                                    "@type": "PriceSpecification",
                                    "priceCurrency": "USD",
                                    "price": "120.00",
                                    "priceType": "Subscription",
                                    "billingDuration": "P1Y"
                                }
                            },
                            {
                                "@type": "Offer",
                                "url": "https://www.gettoggles.com/pricing",
                                "priceCurrency": "USD",
                                "price": "19.00",
                                "priceValidUntil": "2025-12-31",
                                "name": "Business Pro (Monthly)",
                                "description": "Monthly plan for teams with advanced email automation tools, team collaboration, and enhanced functionality.",
                                "eligibleRegion": "US",
                                "priceSpecification": {
                                    "@type": "PriceSpecification",
                                    "priceCurrency": "USD",
                                    "price": "19.00",
                                    "priceType": "Subscription",
                                    "billingDuration": "P1M"
                                }
                            },
                            {
                                "@type": "Offer",
                                "url": "https://www.gettoggles.com/pricing",
                                "priceCurrency": "USD",
                                "price": "180.00",
                                "priceValidUntil": "2025-12-31",
                                "name": "Business Pro (Annual)",
                                "description": "Annual plan for teams with access to full features, customizable email automation, and priority support.",
                                "eligibleRegion": "US",
                                "priceSpecification": {
                                    "@type": "PriceSpecification",
                                    "priceCurrency": "USD",
                                    "price": "180.00",
                                    "priceType": "Subscription",
                                    "billingDuration": "P1Y"
                                }
                            }
                        ],
                        "additionalProperty": [
                            {
                                "@type": "PropertyValue",
                                "name": "Free Trial",
                                "value": "7-day free trial available for Premium Plus and Business Pro plans"
                            },
                            {
                                "@type": "PropertyValue",
                                "name": "Team Collaboration",
                                "value": "Available in Business Pro plan only"
                            },
                            {
                                "@type": "PropertyValue",
                                "name": "Features",
                                "value": "Email templates, workflow automation, document attachment management, and more"
                            }
                        ]
                    }
                `}
            </script>
        </Helmet>
    );
}
