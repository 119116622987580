// @ts-nocheck
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import ArticleIcon from "@mui/icons-material/Article";
import DrawIcon from "@mui/icons-material/Draw";
import SnoozeIcon from "@mui/icons-material/Snooze";

const ACTION_TYPES = {
    Tag: "tag",
    Recipient: "recipient",
    Attachment: "attachment",
    Link: "link",
    Template: "template",
    Signature: "signature",
    Delay: "delay",
};

import "./styles.scss";

const ActionsList = ({ rules, enabledRules }) => {
    const [enabledTypes, setEnabledTypes] = useState({
        [ACTION_TYPES.Tag]: false,
        [ACTION_TYPES.Recipient]: false,
        [ACTION_TYPES.Attachment]: false,
        [ACTION_TYPES.Link]: false,
        [ACTION_TYPES.Template]: false,
        [ACTION_TYPES.Signature]: false,
        [ACTION_TYPES.Delay]: false,
    });

    useEffect(() => {
        const defaults = {
            [ACTION_TYPES.Tag]: false,
            [ACTION_TYPES.Recipient]: false,
            [ACTION_TYPES.Attachment]: false,
            [ACTION_TYPES.Link]: false,
            [ACTION_TYPES.Template]: false,
            [ACTION_TYPES.Signature]: false,
            [ACTION_TYPES.Delay]: false,
        };

        if (!enabledRules || enabledRules.length === 0) {
            setEnabledTypes(defaults);
            return;
        }

        // For each enabledRule (id array), find the rule that matches and check action types
        const types = enabledRules.reduce((acc, ruleId) => {
            const rule = rules.find((r) => r.id === ruleId);
            rule.metadata.actions.forEach((action) => {
                acc[action.type] = true;
            });
            return acc;
        }, {});

        // Set enabledTypes
        setEnabledTypes((prev) => ({ ...defaults, ...types }));
    }, [enabledRules]);
    
    return (
        <div className="demo-actions-list">
            <div className={`action ${enabledTypes[ACTION_TYPES.Tag] ? 'active' : ''}`}>
                <LocalOfferIcon className="action-icon" />
                <Typography
                    className="action-label"
                    variant="h6"
                    align="center"
                >
                    Subject
                </Typography>
            </div>
            <div className={`action ${enabledTypes[ACTION_TYPES.Recipient] ? 'active' : ''}`}>
                <PersonAddAlt1Icon className="action-icon" />
                <Typography
                    className="action-label"
                    variant="h6"
                    align="center"
                >
                    Recipients
                </Typography>
            </div>
            <div className={`action ${enabledTypes[ACTION_TYPES.Attachment] ? 'active' : ''}`}>
                <AttachFileIcon className="action-icon" />
                <Typography
                    className="action-label"
                    variant="h6"
                    align="center"
                >
                    Attachments
                </Typography>
            </div>
            <div className={`action ${enabledTypes[ACTION_TYPES.Link] ? 'active' : ''}`}>
                <InsertLinkIcon className="action-icon" />
                <Typography
                    className="action-label"
                    variant="h6"
                    align="center"
                >
                    Links
                </Typography>
            </div>
            <div className={`action ${enabledTypes[ACTION_TYPES.Template] ? 'active' : ''}`}>
                <ArticleIcon className="action-icon" />
                <Typography
                    className="action-label"
                    variant="h6"
                    align="center"
                >
                    Templates
                </Typography>
            </div>
            <div className={`action ${enabledTypes[ACTION_TYPES.Signature] ? 'active' : ''}`}>
                <DrawIcon className="action-icon" />
                <Typography
                    className="action-label"
                    variant="h6"
                    align="center"
                >
                    Signatures
                </Typography>
            </div>
            <div className={`action ${enabledTypes[ACTION_TYPES.Delay] ? 'active' : ''}`}>
                <SnoozeIcon className="action-icon" />
                <Typography
                    className="action-label"
                    variant="h6"
                    align="center"
                >
                    Schedule
                </Typography>
            </div>
        </div>
    );
};

export default ActionsList;
