import React from "react";
import { Helmet } from "react-helmet-async";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import Demo from "../../components/Demo";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import CelebrationIcon from "@mui/icons-material/Celebration";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import templatesFeatureGraphic from "../../assets/bp-templates.png";
import signaturesFeatureGraphic from "../../assets/bp-signatures.png";
import oneClickToggle from "../../assets/bp-one-click3.png";
import solutionsCtaGraphic from "../../assets/solutions-cs-cta.png";
import csHeroGraphic from "../../assets/cs-hero.png";
import ROICalculator from "../../components/ROICalculator";

import "./solutions.scss";

export default function CustomerSuccess() {
    return (
        <Container maxWidth="lg">
            <Metadata />

            {/* HERO */}
            <div className="solution-hero split-section">
                <Grid container>
                    <Grid xs={12} lg={5} className="text-content">
                        <Typography className="text-title mb-30" variant="h1">
                            Streamline Your Customer Success Team's
                            Communications
                        </Typography>
                        <Typography
                            className="text-description"
                            variant="body1"
                        >
                            In Customer Success, efficient and consistent
                            communication is crucial. Toggles helps your team
                            streamline client onboarding, manage follow-ups, and
                            resolve issues faster, ensuring every interaction is
                            professional and time-efficient.
                        </Typography>
                        <div className="cta-buttons">
                            <Button
                                className="cta-button"
                                variant="outlined"
                                size="large"
                                href="https://hub.togglesforemail.com/signup?type=business"
                            >
                                Get started free
                            </Button>
                            <Button
                                className="cta-button demo-request"
                                variant="outlined"
                                size="large"
                                href="/request-demo"
                            >
                                Get a demo
                            </Button>
                        </div>
                    </Grid>
                    <Grid xs={12} lg={7} className="graphic-container">
                        <img src={csHeroGraphic} alt="Human Resources Hero" />
                    </Grid>
                </Grid>
            </div>

            <div className="solution-demo full-width blue-dark section">
                <Container maxWidth="lg">
                    <Typography
                        className="section-title fwidth"
                        variant="h2"
                        align="center"
                    >
                        Explore time-saving workflows used by Customer Success
                        teams
                    </Typography>

                    <Demo
                        showTypeOptions={false}
                        userTypes={[
                            {
                                name: "support",
                                icon: (
                                    <SupportAgentOutlinedIcon className="audience-item-icon" />
                                ),
                            },
                        ]}
                    />
                </Container>
            </div>

            <div className="section full-width solutions-roi-calculator">
                <ROICalculator />
            </div>

            <div className="section full-width blue-dark solution-painpoints">
                <Container maxWidth="xl">
                    <Grid spacing={4} container>
                        <Grid className="painpoint-box" xs={12} md={4}>
                            <div className="painpoint-graphic">
                                <CelebrationIcon className="painpoint-icon" />
                            </div>
                            <Typography
                                className="painpoint-title"
                                variant="h3"
                                align="center"
                            >
                                Reduce Burnout
                            </Typography>
                            <Typography
                                className="painpoint-description"
                                variant="subtitle1"
                                align="center"
                            >
                                Spend more time strengthening client
                                relationships and less time on repetitive
                                emails.
                            </Typography>
                        </Grid>
                        <Grid className="painpoint-box" xs={12} md={4}>
                            <div className="painpoint-graphic">
                                <SettingsSuggestIcon className="painpoint-icon" />
                            </div>
                            <Typography
                                className="painpoint-title"
                                variant="h3"
                                align="center"
                            >
                                Increase Productivity
                            </Typography>
                            <Typography
                                className="painpoint-description"
                                variant="subtitle1"
                                align="center"
                            >
                                Communicate effectively and confidently with
                                clients in a fraction of the time.
                            </Typography>
                        </Grid>
                        <Grid className="painpoint-box" xs={12} md={4}>
                            <div className="painpoint-graphic">
                                <Diversity2Icon className="painpoint-icon" />
                            </div>
                            <Typography
                                className="painpoint-title"
                                variant="h3"
                                align="center"
                            >
                                Always On-Brand
                            </Typography>
                            <Typography
                                className="painpoint-description"
                                variant="subtitle1"
                                align="center"
                            >
                                Ensure every email sent by your team reflects
                                your company's standards and professionalism.
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <div className="split-cta full-width light">
                <Container maxWidth="lg">
                    <Grid container>
                        <Grid
                            xs={12}
                            md={8}
                            order={{ xs: 2, md: 1 }}
                            className="text-content"
                        >
                            <Typography
                                className="text-title mb-30"
                                variant="h3"
                            >
                                Transform your teams client communications
                            </Typography>
                            <Typography
                                className="text-description"
                                variant="body1"
                            >
                                In Customer Success, building trust and
                                resolving issues efficiently is key. Toggles
                                empowers your team to automate client
                                follow-ups, streamline onboarding, and improve
                                team productivity, so you can focus on
                                delivering exceptional service.
                            </Typography>
                            <div className="cta-buttons">
                                <Button
                                    className="cta-button"
                                    variant="outlined"
                                    size="large"
                                    href="https://hub.togglesforemail.com/signup?type=business"
                                >
                                    Get started free
                                </Button>
                                <Button
                                    className="cta-button demo-request"
                                    variant="outlined"
                                    size="large"
                                    href="/request-demo"
                                >
                                    Get a demo
                                </Button>
                            </div>
                        </Grid>
                        <Grid
                            xs={12}
                            md={4}
                            order={{ xs: 1, md: 2 }}
                            className="graphic-container"
                        >
                            <img
                                src={solutionsCtaGraphic}
                                alt="Toggles for Outlook - Human Resources Graphic"
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <div className="section feature">
                <Container maxWidth="xl">
                    <Grid container spacing={3}>
                        <Grid xs={12} md={6} className="text-content">
                            <Typography className="section-title" variant="h2">
                                Automate client communications with ease
                            </Typography>
                            <Typography variant="h6" className="mb-30">
                                Build workflows to craft each part of client
                                emails in one click. Focus on client success
                                rather than repetitive tasks, while ensuring
                                consistency and professionalism.
                            </Typography>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <img
                                className="feature-img"
                                src={oneClickToggle}
                                alt="Workflows enabled in a single click"
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="section feature">
                <Container maxWidth="xl">
                    <Grid container spacing={3}>
                        <Grid xs={12} md={6} order={{ xs: 2, md: 1 }}>
                            <img
                                className="feature-img"
                                src={templatesFeatureGraphic}
                                alt="Shared email templates for teams"
                            />
                        </Grid>
                        <Grid
                            xs={12}
                            md={6}
                            order={{ xs: 1, md: 2 }}
                            className="text-content"
                        >
                            <Typography className="section-title" variant="h2">
                                Consistent messaging for every client
                            </Typography>
                            <Typography variant="h6">
                                Create a shared library of templates to
                                standardize client interactions. Ensure every
                                email aligns with your messaging and meets
                                client expectations.
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="section feature">
                <Container maxWidth="xl">
                    <Grid container spacing={3}>
                        <Grid xs={12} md={6} className="text-content">
                            <Typography className="section-title" variant="h2">
                                Polished and professional signatures
                            </Typography>
                            <Typography variant="h6">
                                Easily manage team-wide email signatures to
                                maintain a professional image. Keep client
                                communication polished and aligned with your
                                brand.
                            </Typography>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <img
                                className="feature-img"
                                src={signaturesFeatureGraphic}
                                alt="Shared email signatures for teams"
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <div className="section full-width cta-curved">
                <Typography
                    className="section-title"
                    variant="h2"
                    align="center"
                >
                    Your team's productivity is key to customer success
                </Typography>
                <div className="cta-buttons">
                    <Button
                        className="cta-btn contained"
                        variant="outlined"
                        size="large"
                        href="https://hub.togglesforemail.com/signup?type=business"
                    >
                        Get started
                    </Button>
                    <Button
                        className="cta-btn outlined"
                        variant="outlined"
                        size="large"
                        href="/request-demo"
                    >
                        Talk to sales
                    </Button>
                </div>
            </div>
        </Container>
    );
}

function Metadata() {
    return (
        <Helmet>
            <title>Toggles | Email Automation for Customer Success</title>
            <meta
                name="description"
                content="Elevate client communication with Toggles for Outlook. Automate customer follow-ups, onboarding, and issue resolution to save time and improve team productivity. Free trial available!"
            />
            <meta
                itemProp="name"
                content="Toggles | Email Automation for Customer Success"
            />
            <meta
                itemProp="description"
                content="Elevate client communication with Toggles for Outlook. Automate customer follow-ups, onboarding, and issue resolution to save time and improve team productivity. Free trial available!"
            />
            <meta
                itemProp="image"
                content="http://www.gettoggles.com/graphics/meta-fb-image-1200x630.jpg"
            />
            <meta
                property="og:url"
                content="https://www.gettoggles.com/solutions/customer-success"
            />
            <meta property="og:type" content="website" />
            <meta
                property="og:title"
                content="Toggles | Email Automation for Customer Success"
            />
            <meta
                property="og:description"
                content="Elevate client communication with Toggles for Outlook. Automate customer follow-ups, onboarding, and issue resolution to save time and improve team productivity. Free trial available!"
            />
            <meta
                property="og:image"
                content="https://www.gettoggles.com/graphics/meta-fb-image-1200x630.jpg"
            />
            <meta name="twitter:site" content="@togglesforemail" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="twitter:title"
                content="Toggles | Email Automation for Customer Success"
            />
            <meta
                name="twitter:description"
                content="Elevate client communication with Toggles for Outlook. Automate customer follow-ups, onboarding, and issue resolution to save time and improve team productivity. Free trial available!"
            />
            <meta
                name="twitter:image"
                content="https://www.gettoggles.com/graphics/meta-twitter-image-1600x900.jpg"
            />
            <link
                rel="canonical"
                href="https://www.gettoggles.com/solutions/customer-success"
            />
            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "url": "https://www.gettoggles.com/solutions/customer-success",
                        "name": "Toggles for Customer Success Teams",
                        "description": "Discover how Toggles for Outlook helps Customer Success teams streamline email workflows, maintain consistency with templates, and save time while staying focused on client success.",
                        "about": {
                            "@type": "Thing",
                            "name": "Customer Success",
                            "description": "Solutions designed for Customer Success teams to enhance productivity and efficiency in email communication and client interactions."
                        },
                        "publisher": {
                            "@type": "Organization",
                            "name": "Toggles for Outlook",
                            "url": "https://www.gettoggles.com",
                            "logo": {
                            "@type": "ImageObject",
                            "url": "https://www.gettoggles.com/logo.png",
                            "width": 600,
                            "height": 60
                            },
                            "contactPoint": {
                            "@type": "ContactPoint",
                            "email": "support@gettoggles.com",
                            "contactType": "Customer Support",
                            "url": "https://www.gettoggles.com/contact"
                            }
                        },
                        "audience": {
                            "@type": "Audience",
                            "audienceType": "Customer Success Teams",
                            "description": "Tailored for Customer Success professionals in industries such as SaaS, professional services, and financial services."
                        },
                        "isPartOf": {
                            "@type": "WebSite",
                            "name": "Toggles for Outlook",
                            "url": "https://www.gettoggles.com"
                        },
                        "potentialAction": {
                            "@type": "ReadAction",
                            "target": "https://www.gettoggles.com/solutions/customer-success"
                        }
                    }
                `}
            </script>
        </Helmet>
    );
}
