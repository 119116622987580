import React from "react";

import "./styles.scss";

const TutorialVideo = ({ embedId = "", title = "" }) => (
    <div className="video-responsive">
        <iframe
            width="100%"
            height="auto"
            src={`https://www.youtube.com/embed/${embedId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={title}
        />
    </div>
);

export default TutorialVideo;
