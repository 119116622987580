import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import "./styles.scss";

const ANNUAL_COST_PER_USER = 180;

export default function ROICalculator({
    title = "Discover your potential savings.",
    description = "Use the calculator below to calculate the estimated savings and ROI that your team can gain by using Toggles.",
    showTitle = true,
    showDescription = true,
    showCTAs = true,
}) {
    const [numEmployees, setNumEmployees] = useState(10);
    const [salary, setSalary] = useState(50000);
    const [hoursSaved, setHoursSaved] = useState(3);

    const getCostPerYear = () => {
        return numEmployees * ANNUAL_COST_PER_USER;
    };

    const getHoursSavedperYear = () => {
        return numEmployees * hoursSaved * 52;
    };

    const getSavingsPerYear = () => {
        return getHoursSavedperYear() * (salary / 52 / 40);
    };

    const getReplacedFTEs = () => {
        return getHoursSavedperYear() / 2080;
    };

    const getROI = () => {
        return (getSavingsPerYear() / getCostPerYear()) * 100;
    };

    const salaryValueFormat = (value: number) => {
        return `$${value.toLocaleString()}`;
    };

    const hoursValueFormat = (value: number) => {
        return `${value} hrs`;
    };

    return (
        <div className="roi-calculator">
            <Container maxWidth="lg">
                <Grid container spacing={3} className="container">
                    {(showTitle || showDescription) && (
                        <Grid xs={12} className="text-content roi-header">
                            {showTitle && (
                                <Typography variant="h2">{title}</Typography>
                            )}
                            {showDescription && (
                                <Typography variant="h5" className="subtitle">
                                    {description}
                                </Typography>
                            )}
                        </Grid>
                    )}
                    <Grid className="roi-form" xs={12} md={5}>
                        <div className="roi-input">
                            <Typography
                                className="label"
                                variant="h6"
                                component="div"
                                gutterBottom
                            >
                                # of team members using Toggles
                                <Tooltip
                                    placement="top"
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    slotProps={{
                                        tooltip: {
                                            sx: {
                                                fontSize: "0.9em",
                                                fontWeight: 400,
                                            },
                                        },
                                    }}
                                    title="The number of team members you plan to onboard with Toggles. Adjust this to reflect the size of your team."
                                    arrow
                                >
                                    <InfoOutlinedIcon className="tooltip-icon" />
                                </Tooltip>
                            </Typography>
                            <Slider
                                className="slider"
                                aria-label="Number of employees"
                                value={numEmployees}
                                onChange={(e, value) => {
                                    setNumEmployees(value as number);
                                }}
                                valueLabelDisplay="on"
                                step={5}
                                min={5}
                                max={500}
                            />
                        </div>
                        <div className="roi-input">
                            <Typography
                                className="label"
                                variant="h6"
                                component="div"
                                gutterBottom
                            >
                                Average annual salary of team member
                                <Tooltip
                                    placement="top"
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    slotProps={{
                                        tooltip: {
                                            sx: {
                                                fontSize: "0.9em",
                                                fontWeight: 400,
                                            },
                                        },
                                    }}
                                    title="The average annual salary of the team members who will use Toggles. This helps estimate the monetary value of time saved."
                                    arrow
                                >
                                    <InfoOutlinedIcon className="tooltip-icon" />
                                </Tooltip>
                            </Typography>
                            <Slider
                                className="slider"
                                aria-label="Average annual salary of team member"
                                value={salary}
                                onChange={(e, value) => {
                                    setSalary(value as number);
                                }}
                                valueLabelDisplay="on"
                                getAriaValueText={salaryValueFormat}
                                valueLabelFormat={salaryValueFormat}
                                step={5000}
                                min={25000}
                                max={250000}
                            />
                        </div>
                        <div className="roi-input">
                            <Typography
                                className="label"
                                variant="h6"
                                component="div"
                                gutterBottom
                            >
                                Hours saved per week / team member
                                <Tooltip
                                    placement="top"
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    slotProps={{
                                        tooltip: {
                                            sx: {
                                                fontSize: "0.9em",
                                                fontWeight: 400,
                                            },
                                        },
                                    }}
                                    title="The average number of hours each team member saves per week by using Toggles. Toggles customers report saving 1–8 hours weekly depending on their email tasks."
                                    arrow
                                >
                                    <InfoOutlinedIcon className="tooltip-icon" />
                                </Tooltip>
                            </Typography>
                            <Slider
                                className="slider"
                                aria-label="Aerage number of hours saved per week"
                                value={hoursSaved}
                                onChange={(e, value) => {
                                    setHoursSaved(value as number);
                                }}
                                valueLabelDisplay="on"
                                getAriaValueText={hoursValueFormat}
                                valueLabelFormat={hoursValueFormat}
                                step={0.5}
                                min={0.5}
                                max={20}
                            />
                        </div>
                        <Typography variant="body1" className="roi-disclaimer">
                            Toggles customers report saving anywhere between 1-8
                            hours per week for each employee using Toggles for
                            Outlook. This will depend on how many repetitive
                            emails a user sends and how much time they spend
                            composing each email.
                        </Typography>
                    </Grid>
                    <Grid className="roi-info" xs={12} md={7}>
                        <div className="roi-info-section">
                            <div className="roi-info-label large">
                                Annual ROI
                                <Tooltip
                                    placement="top"
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    slotProps={{
                                        tooltip: {
                                            sx: {
                                                fontSize: "0.9em",
                                                fontWeight: 400,
                                            },
                                        },
                                    }}
                                    title="Annual ROI (Return on Investment) measures the value gained from using Toggles for Outlook compared to the cost of the subscription. A higher ROI means greater cost-effectiveness for your team."
                                    arrow
                                >
                                    <InfoOutlinedIcon className="tooltip-icon" />
                                </Tooltip>
                            </div>
                            <div className="roi-info-value large">
                                {Number(getROI().toFixed()).toLocaleString()}%
                            </div>
                        </div>
                        <div className="roi-info-section">
                            <div className="roi-info-label">
                                Toggles annual cost
                                <Tooltip
                                    placement="top"
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    slotProps={{
                                        tooltip: {
                                            sx: {
                                                fontSize: "0.9em",
                                                fontWeight: 400,
                                            },
                                        },
                                    }}
                                    title="The annual cost of Toggles for Outlook for the number of team members you have entered."
                                    arrow
                                >
                                    <InfoOutlinedIcon className="tooltip-icon" />
                                </Tooltip>
                            </div>
                            <div className="roi-info-value">
                                ${getCostPerYear().toLocaleString()}
                            </div>
                        </div>
                        <div className="roi-info-section">
                            <div className="roi-info-label">
                                Projected producitivity benefits
                                <Tooltip
                                    placement="top"
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    slotProps={{
                                        tooltip: {
                                            sx: {
                                                fontSize: "0.9em",
                                                fontWeight: 400,
                                            },
                                        },
                                    }}
                                    title="The estimated dollar value of time saved by your team, calculated as:
                                        Projected hours saved × Average hourly rate.
                                        This highlights how Toggles directly impacts your team's efficiency."
                                    arrow
                                >
                                    <InfoOutlinedIcon className="tooltip-icon" />
                                </Tooltip>
                            </div>
                            <div className="roi-info-value">
                                ${getSavingsPerYear().toLocaleString()}
                            </div>
                        </div>
                        <div className="roi-info-section">
                            <div className="roi-info-label">
                                Projected hours saved per year
                                <Tooltip
                                    placement="top"
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    slotProps={{
                                        tooltip: {
                                            sx: {
                                                fontSize: "0.9em",
                                                fontWeight: 400,
                                            },
                                        },
                                    }}
                                    title="This represents the total number of hours your team will save annually by using Toggles for repetitive email tasks. It’s calculated as:
                                        Team members × Hours saved per week × 52 weeks."
                                    arrow
                                >
                                    <InfoOutlinedIcon className="tooltip-icon" />
                                </Tooltip>
                            </div>
                            <div className="roi-info-value">
                                {getHoursSavedperYear().toLocaleString()}
                            </div>
                        </div>
                        <div className="roi-info-section">
                            <div className="roi-info-label">
                                Full-Time Employees equivalent
                                <Tooltip
                                    placement="top"
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    slotProps={{
                                        tooltip: {
                                            sx: {
                                                fontSize: "0.9em",
                                                fontWeight: 400,
                                            },
                                        },
                                    }}
                                    title="FTE (Full-Time Equivalent) represents the time savings in terms of hiring an additional employee. For example, saving 2,080 hours per year is equivalent to one employee working full-time (40 hours/week for 52 weeks)."
                                    arrow
                                >
                                    <InfoOutlinedIcon className="tooltip-icon" />
                                </Tooltip>
                            </div>
                            <div className="roi-info-value">
                                {Number(
                                    getReplacedFTEs().toFixed(2)
                                ).toLocaleString()}
                            </div>
                        </div>
                        {showCTAs && (
                            <>
                                <Button
                                    className="cta-button"
                                    variant="contained"
                                    size="large"
                                    href="https://hub.togglesforemail.com/signup?type=business"
                                >
                                    Get started free
                                </Button>
                                <Button
                                    className="cta-button"
                                    variant="contained"
                                    size="large"
                                    href="/request-demo"
                                >
                                    Request a Demo
                                </Button>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

// ROI Calculator
// Hours / Week Saved
// # of users
// Average Annual Salary

// Toggles Cost / week
// Toggles Cost / year
// Savings / week
// Savings / year
// ROI
