import React from "react";
import { Helmet } from "react-helmet-async";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import Demo from "../../components/Demo";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import CelebrationIcon from "@mui/icons-material/Celebration";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import templatesFeatureGraphic from "../../assets/bp-templates.png";
import signaturesFeatureGraphic from "../../assets/bp-signatures.png";
import oneClickToggle from "../../assets/bp-one-click3.png";
import solutionsCtaGraphic from "../../assets/solutions-hr-cta.png";
import hrHeroGraphic from "../../assets/hr-hero.png";
import ROICalculator from "../../components/ROICalculator";

import "./solutions.scss";

export default function HumanResources() {
    return (
        <Container maxWidth="lg">
            <Metadata />

            {/* HERO */}
            <div className="solution-hero split-section">
                <Grid container>
                    <Grid xs={12} lg={5} className="text-content">
                        <Typography className="text-title mb-30" variant="h1">
                            Streamline your HR team's daily communications
                        </Typography>
                        <Typography
                            className="text-description"
                            variant="body1"
                        >
                            In Human Resources, efficient communication is key
                            to success. Toggles helps HR teams streamline new
                            employee onboarding, manage interview
                            communications, and simplify day-to-day
                            interactions, saving time and ensuring consistency
                            across every touchpoint.
                        </Typography>
                        <div className="cta-buttons">
                            <Button
                                className="cta-button"
                                variant="outlined"
                                size="large"
                                href="https://hub.togglesforemail.com/signup?type=business"
                            >
                                Get started free
                            </Button>
                            <Button
                                className="cta-button demo-request"
                                variant="outlined"
                                size="large"
                                href="/request-demo"
                            >
                                Get a demo
                            </Button>
                        </div>
                    </Grid>
                    <Grid xs={12} lg={7} className="graphic-container">
                        <img src={hrHeroGraphic} alt="Human Resources Hero" />
                    </Grid>
                </Grid>
            </div>

            <div className="solution-demo full-width blue-dark section">
                <Container maxWidth="lg">
                    <Typography
                        className="section-title fwidth"
                        variant="h2"
                        align="center"
                    >
                        Explore time-saving workflows used by HR teams
                    </Typography>

                    <Demo
                        showTypeOptions={false}
                        userTypes={[
                            {
                                name: "hr",
                                icon: (
                                    <Diversity3Icon className="audience-item-icon" />
                                ),
                            },
                        ]}
                    />
                </Container>
            </div>

            <div className="section full-width solutions-roi-calculator">
                <ROICalculator />
            </div>

            <div className="section full-width blue-dark solution-painpoints">
                <Container maxWidth="xl">
                    <Grid spacing={4} container>
                        <Grid className="painpoint-box" xs={12} md={4}>
                            <div className="painpoint-graphic">
                                <CelebrationIcon className="painpoint-icon" />
                            </div>
                            <Typography
                                className="painpoint-title"
                                variant="h3"
                                align="center"
                            >
                                Reduce Burnout
                            </Typography>
                            <Typography
                                className="painpoint-description"
                                variant="subtitle1"
                                align="center"
                            >
                                Spend more time engaging with employees and less
                                time on repetitive emails.
                            </Typography>
                        </Grid>
                        <Grid className="painpoint-box" xs={12} md={4}>
                            <div className="painpoint-graphic">
                                <SettingsSuggestIcon className="painpoint-icon" />
                            </div>
                            <Typography
                                className="painpoint-title"
                                variant="h3"
                                align="center"
                            >
                                Increase Productivity
                            </Typography>
                            <Typography
                                className="painpoint-description"
                                variant="subtitle1"
                                align="center"
                            >
                                Communicate clearly and confidently with your
                                organization in a fraction of the time.
                            </Typography>
                        </Grid>
                        <Grid className="painpoint-box" xs={12} md={4}>
                            <div className="painpoint-graphic">
                                <Diversity2Icon className="painpoint-icon" />
                            </div>
                            <Typography
                                className="painpoint-title"
                                variant="h3"
                                align="center"
                            >
                                Always On-Brand
                            </Typography>
                            <Typography
                                className="painpoint-description"
                                variant="subtitle1"
                                align="center"
                            >
                                Ensure every email sent by your team is
                                on-brand, compliant, and professional.
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <div className="split-cta full-width light">
                <Container maxWidth="lg">
                    <Grid container>
                        <Grid
                            xs={12}
                            md={8}
                            order={{ xs: 2, md: 1 }}
                            className="text-content"
                        >
                            <Typography
                                className="text-title mb-30"
                                variant="h3"
                            >
                                Transform your teams communications
                            </Typography>
                            <Typography
                                className="text-description"
                                variant="body1"
                            >
                                In Human Resources, clear and consistent
                                communication is essential for employee trust
                                and engagement. Toggles empowers HR teams to
                                automate routine updates, maintain compliance,
                                and save time on repetitive messaging, so they
                                can focus more on people and less on processes.
                            </Typography>
                            <div className="cta-buttons">
                                <Button
                                    className="cta-button"
                                    variant="outlined"
                                    size="large"
                                    href="https://hub.togglesforemail.com/signup?type=business"
                                >
                                    Get started free
                                </Button>
                                <Button
                                    className="cta-button demo-request"
                                    variant="outlined"
                                    size="large"
                                    href="/request-demo"
                                >
                                    Get a demo
                                </Button>
                            </div>
                        </Grid>
                        <Grid
                            xs={12}
                            md={4}
                            order={{ xs: 1, md: 2 }}
                            className="graphic-container"
                        >
                            <img
                                src={solutionsCtaGraphic}
                                alt="Toggles for Outlook - Human Resources Graphic"
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <div className="section feature">
                <Container maxWidth="xl">
                    <Grid container spacing={3}>
                        <Grid xs={12} md={6} className="text-content">
                            <Typography className="section-title" variant="h2">
                                Automate employee communications with ease
                            </Typography>
                            <Typography variant="h6" className="mb-30">
                                Build workflows that craft each part of HR
                                emails in one click. Ensure consistency and
                                professionalism across the team, letting you
                                focus on supporting employees rather than
                                repetitive messages.
                            </Typography>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <img
                                className="feature-img"
                                src={oneClickToggle}
                                alt="Workflows enabled in a single click"
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="section feature">
                <Container maxWidth="xl">
                    <Grid container spacing={3}>
                        <Grid xs={12} md={6} order={{ xs: 2, md: 1 }}>
                            <img
                                className="feature-img"
                                src={templatesFeatureGraphic}
                                alt="Shared email templates for teams"
                            />
                        </Grid>
                        <Grid
                            xs={12}
                            md={6}
                            order={{ xs: 1, md: 2 }}
                            className="text-content"
                        >
                            <Typography className="section-title" variant="h2">
                                Consistent messaging for every employee
                            </Typography>
                            <Typography variant="h6">
                                Create a shared library of templates to keep
                                employee communications uniform and accurate.
                                Ensure every message aligns with policies and
                                reflects your organization's standards.
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="section feature">
                <Container maxWidth="xl">
                    <Grid container spacing={3}>
                        <Grid xs={12} md={6} className="text-content">
                            <Typography className="section-title" variant="h2">
                                Professional and standardized signatures
                            </Typography>
                            <Typography variant="h6">
                                Manage team-wide email signatures with ease to
                                end every HR message on a polished note. Keep
                                sign-offs consistent and aligned with company
                                branding.
                            </Typography>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <img
                                className="feature-img"
                                src={signaturesFeatureGraphic}
                                alt="Shared email signatures for teams"
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <div className="section full-width cta-curved">
                <Typography
                    className="section-title"
                    variant="h2"
                    align="center"
                >
                    Spend less time on emails, more time on your people.
                </Typography>
                <div className="cta-buttons">
                    <Button
                        className="cta-btn contained"
                        variant="outlined"
                        size="large"
                        href="https://hub.togglesforemail.com/signup?type=business"
                    >
                        Get started
                    </Button>
                    <Button
                        className="cta-btn outlined"
                        variant="outlined"
                        size="large"
                        href="/request-demo"
                    >
                        Talk to sales
                    </Button>
                </div>
            </div>
        </Container>
    );
}

function Metadata() {
    return (
        <Helmet>
            <title>Toggles | Email Automation for HR Professionals</title>
            <meta
                name="description"
                content="Boost client communication efficiency in human resources with Toggles for Outlook. Automate employee onboarding, interview processes, and day-to-day communications. Free trial Available!"
            />
            <meta
                itemProp="name"
                content="Toggles | Email Automation for HR Professionals"
            />
            <meta
                itemProp="description"
                content="Boost client communication efficiency in human resources with Toggles for Outlook. Automate employee onboarding, interview processes, and day-to-day communications. Free trial Available!"
            />
            <meta
                itemProp="image"
                content="http://www.gettoggles.com/graphics/meta-fb-image-1200x630.jpg"
            />
            <meta
                property="og:url"
                content="https://www.gettoggles.com/solutions/human-resources"
            />
            <meta property="og:type" content="website" />
            <meta
                property="og:title"
                content="Toggles | Email Automation for HR Professionals"
            />
            <meta
                property="og:description"
                content="Boost client communication efficiency in human resources with Toggles for Outlook. Automate employee onboarding, interview processes, and day-to-day communications. Free trial Available!"
            />
            <meta
                property="og:image"
                content="https://www.gettoggles.com/graphics/meta-fb-image-1200x630.jpg"
            />
            <meta name="twitter:site" content="@togglesforemail" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="twitter:title"
                content="Toggles | Email Automation for HR Professionals"
            />
            <meta
                name="twitter:description"
                content="Boost client communication efficiency in human resources with Toggles for Outlook. Automate employee onboarding, interview processes, and day-to-day communications. Free trial Available!"
            />
            <meta
                name="twitter:image"
                content="https://www.gettoggles.com/graphics/meta-twitter-image-1600x900.jpg"
            />
            <link
                rel="canonical"
                href="https://www.gettoggles.com/solutions/human-resources"
            />
            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "url": "https://www.gettoggles.com/solutions/human-resources",
                        "name": "Toggles for Human Resources Teams",
                        "description": "Simplify email communication for HR teams with Toggles for Outlook. Perfect for recruitment, onboarding, and employee communication.",
                        "about": {
                            "@type": "Thing",
                            "name": "Human Resources Solutions",
                            "description": "Custom tools for HR teams to enhance communication, streamline hiring processes, and improve internal operations."
                        },
                        "publisher": {
                            "@type": "Organization",
                            "name": "Toggles for Outlook",
                            "url": "https://www.gettoggles.com",
                            "logo": {
                            "@type": "ImageObject",
                            "url": "https://www.gettoggles.com/logo.png",
                            "width": 600,
                            "height": 60
                            },
                            "contactPoint": {
                            "@type": "ContactPoint",
                            "email": "support@gettoggles.com",
                            "contactType": "Customer Support",
                            "url": "https://www.gettoggles.com/contact"
                            }
                        },
                        "audience": {
                            "@type": "Audience",
                            "audienceType": "Human Resources Teams",
                            "description": "Designed for HR professionals involved in recruitment, onboarding, and employee communication."
                        },
                        "isPartOf": {
                            "@type": "WebSite",
                            "name": "Toggles for Outlook",
                            "url": "https://www.gettoggles.com"
                        },
                        "potentialAction": {
                            "@type": "ReadAction",
                            "target": "https://www.gettoggles.com/solutions/human-resources"
                        }
                    }
                `}
            </script>
        </Helmet>
    );
}
