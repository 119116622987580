import * as React from "react";
import { Helmet } from "react-helmet-async";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import "./Privacy.scss";

export default function Privacy() {
    return (
        <Container maxWidth="lg" className="privacy-container">
            <Metadata />

            <Typography className="section-title" variant="h2" align="center">
                Privacy Policy
            </Typography>
            <Typography variant="h6" align="center">
                At Toggles we strongly believe in the privacy of our customers
                and end users.
            </Typography>

            <div className="policy">
                <Typography variant="body1" className="mb-40">
                    This privacy policy explains how Toggles handles your
                    personal information and data, and applies to all of the
                    products, services and websites offered by Toggles and their
                    affiliates (collectively, “Toggles”), except where otherwise
                    noted. Toggles products, services and websites are
                    collectively referred to as the “services” in this policy.
                    Unless otherwise noted, all services are provided by
                    Toggles, which is based in the United States.
                </Typography>
                <Typography variant="body1" className="mb-40">
                    In this policy, “you” refers to those who use and/or
                    interact with any or all of our products, services, and
                    websites, and “we”, “us”, and “our” refer to Toggles.
                    “Customer”(s) refers specifically to those who use Toggles
                    services.
                </Typography>

                <Typography variant="h4" className="mb-40 mt-80">
                    INFORMATION WE COLLECT
                </Typography>
                <Typography variant="h5" className="mb-20 mt-40">
                    From Our Customers
                </Typography>
                <ul>
                    <li className="mb-20">
                        <strong>Registration information.</strong> When you
                        register for an account with us so you can use our email
                        add-in or extension, we collect your email address.
                    </li>
                    <li className="mb-20">
                        <strong>Billing information.</strong> If you make a
                        payment to Toggles, we require that you provide your
                        billing details, including name, address, email address
                        and financial information corresponding to your selected
                        method of payment (e.g. a credit card number and
                        expiration date). If you provide a billing address, we
                        will regard that as the location of the account holder.
                        Our integrations with third party payment gateways are
                        for processing only. We don’t store or log any sensitive
                        cardholder data provided by you. We follow
                        industry-standard best practices to protect the security
                        of cardholder data during processing and transmission.
                    </li>
                    <li className="mb-20">
                        <strong>Account settings.</strong> Our customers can set
                        various preferences and personal details on pages, such
                        as your account settings page. For example, your default
                        language, timezone and communication preferences (e.g.
                        opting in or out of receiving marketing emails from us).
                    </li>
                    <li className="mb-20">
                        <strong>
                            Data you use to create rules and actions is owned by
                            you.
                        </strong>{" "}
                        Toggles treats your rules and actions as private, unless
                        you make them available to members of the public. We
                        don’t sell or make rules or actions you’ve created
                        available to anyone for purposes unrelated to you or our
                        services, except in a limited set of circumstances (e.g.
                        Toggles is compelled by a subpoena or court order, or if
                        you’ve given us permission to do so).
                    </li>
                    <li className="mb-20">
                        <strong>
                            For organizational customers, groups and teams are
                            owned by the customer.
                        </strong>{" "}
                        Toggles treats your groups and teams as private. We
                        don’t sell or make groups or teams you’ve created
                        available to anyone for purposes unrelated to you or our
                        services.
                    </li>
                    <li className="mb-20">
                        <strong>
                            Rules and actions data is stored on servers located
                            in the United States.{" "}
                        </strong>
                        More information is available if you are located in
                        Europe - Please contact our Customer Support Team for
                        more information if you reside in Europe. Toggles will
                        process your rules and actions data on your behalf and
                        under your instructions and pursuant to this privacy
                        policy.
                    </li>
                </ul>
                <Typography variant="h5" className="mb-20 mt-40">
                    From Visitors to Our Websites
                </Typography>
                <ul>
                    <li className="mb-20">
                        <strong>Usage data.</strong> We collect usage data when
                        you interact with our services. This may include which
                        web pages you visit, what you click on, when you
                        performed those actions, and so on. Additionally, like
                        most websites today, our web servers keep log files that
                        record data each time a device accesses those servers.
                        The log files contain data about the nature of each
                        access, including originating IP addresses, internet
                        service providers, the files viewed on our site (e.g.,
                        HTML pages, graphics, etc.), operating system versions,
                        and timestamps.
                    </li>
                    <li className="mb-20">
                        <strong>Device data.</strong> We collect data from the
                        device and application you use to access our services,
                        such as your IP address, operating system version,
                        device type, system and performance information, and
                        browser type. We may also infer your geographic location
                        based on your IP address.
                    </li>
                    <li className="mb-20">
                        <strong>Referral data.</strong> If you arrive at a
                        Toggles website from an external source (such as a link
                        on another website or in an email), we record
                        information about the source that referred you to us.
                    </li>
                    <li className="mb-20">
                        <strong>Information from third parties.</strong> We may
                        collect your personal information or data from third
                        parties, if you have given permission to those third
                        parties to share your information.
                    </li>
                    <li className="mb-20">
                        <strong>Information from page tags.</strong> We use
                        third party tracking services that employ cookies and
                        page tags (also known as {' '}
                        <a
                            href="https://en.wikipedia.org/wiki/Web_bug"
                            rel="nofollow noopener"
                        >
                            web beacons
                        </a>
                        ) to collect aggregated and anonymized data about
                        visitors to our websites. This data includes usage and
                        user statistics. Emails sent by Toggles or by users
                        through our services may include page tags that allow
                        the sender to collect information about who opened those
                        emails and clicked on links in them. We do this to allow
                        the email sender to measure the performance of their
                        email messaging and to learn how to improve email
                        deliverability and open rates.
                    </li>
                </ul>

                <Typography variant="h4" className="mb-40 mt-80">
                    HOW WE USE AND DISCLOSE YOUR INFORMATION
                </Typography>
                <Typography variant="h5" className="mb-20 mt-40">
                    Customers
                </Typography>
                <Typography variant="body1" gutterBottom>
                    We treat rules and actions as information that is private,
                    unless a party other than Toggles has made that information
                    public. We don’t use rule or action data other than as
                    described in this privacy policy without our Customers’
                    consent. We don’t sell Customers’ rule or action data, nor
                    do we make it available to third parties without the
                    Customer’s permission.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    We use information gathered from and provided by our
                    Customers to do the following for our Customers:
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Provide services and technical support, assist them with
                    rule and action creation, provide technical troubleshooting,
                    manage our relationship with them, and to gather information
                    on how they use our services.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    If you choose to link your Toggles account with a third
                    party account, such as your Google or Facebook account,
                    Toggles may use the information you allow us to collect from
                    those third parties to provide you with additional features,
                    services, and personalized content.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    In order to provide you with useful options to use the
                    services together with social media and other applications,
                    we may give you the option to export information to, and
                    collect information from, third party applications and
                    websites, including platforms such as Google and Twitter and
                    social networking sites such as Facebook. When exporting and
                    collecting such information, you may be disclosing your
                    information to the individuals or organizations responsible
                    for operating and maintaining such third party applications
                    and sites, and your information may be accessible by others
                    visiting or using those applications or sites. We do not own
                    or operate third party applications or websites that you
                    connect with – you should review the privacy policies and
                    statements of such websites to ensure you are comfortable
                    with the ways in which they use the information you share
                    with them.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>To manage our services. </strong>We use your
                    information, including certain rule and action data, for the
                    following limited purposes:
                </Typography>
                <ul>
                    <li className="mb-20">
                        <strong>
                            To monitor, maintain, and improve our services and
                            features.
                        </strong>
                        We internally perform statistical and other analysis on
                        information we collect, including usage data, device
                        data, referral data, rules and actions data and
                        information from page tags, to analyze and measure user
                        behavior and trends, to understand how people use our
                        services, and to monitor, troubleshoot and improve our
                        services, including to help us evaluate and design new
                        features. We may use your information internally in
                        order to keep our services secure and operational, such
                        as for troubleshooting and testing purposes, and for
                        service improvement, marketing, research and development
                        purposes.
                    </li>
                    <li className="mb-20">
                        <strong>To enforce our Terms of Use.</strong>
                    </li>
                    <li className="mb-20">
                        <strong>
                            To prevent potentially illegal activities.
                        </strong>
                    </li>
                    <li className="mb-20">
                        <strong>
                            To screen for and prevent undesirable or abusive
                            activity.
                        </strong>
                        For example, we have automated systems that screen
                        content for activities such as, phishing, spam, and
                        fraud.
                    </li>
                </ul>
                <Typography variant="body1" gutterBottom>
                    <strong>
                        To create new services, features or content.
                    </strong>{" "}
                    We may use your rule and action data for our internal
                    purposes to create and provide new services, features or
                    content. Regarding rule and action data, we may look at
                    statistics like types of rules and usage, and publish
                    interesting observations about these for informational or
                    marketing purposes. When we do this, no data will be
                    identified or identifiable unless we have obtained the
                    user's permission.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>
                        To facilitate account creation and the logon process.
                    </strong>{" "}
                    If you choose to link your Toggles account to a third party
                    account, such as your Google or Facebook account, we use the
                    information you allowed us to collect from those third
                    parties to facilitate the account creation and login
                    process.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>
                        To contact you about your service or account.
                    </strong>{" "}
                    We will occasionally send you communications of a
                    transactional nature (e.g. service-related announcements,
                    billing-related matters, changes to our services or
                    policies, a welcome email when you first register). You are
                    prevented from opting out of this type of communication
                    since it is required to provide our services to you.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>To contact you for marketing purposes.</strong> We
                    will send you promotional emails at times about new features
                    and company information. You may opt out of these
                    communications at any time by clicking on the “unsubscribe”
                    link in them, or changing the relevant setting on your
                    Account page.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>
                        To respond to legal requests and prevent harm.
                    </strong>{" "}
                    If we receive a subpoena or other legal request for
                    information, we may need to inspect the data held to
                    determine the appropriate response.
                </Typography>

                <Typography variant="h5" className="mb-20 mt-40">
                    Website visitors
                </Typography>
                <Typography variant="body1" gutterBottom>
                    See the section above regarding information related to
                    visitors to our websites. We use that information, including
                    but not limited to cookies, customer data, usage data,
                    device data, referral data and information from page tags,
                    to manage and improve our services, to serve and support our
                    Customers, for research purposes, and for the other various
                    purposes described in this privacy policy.
                </Typography>
                <Typography variant="h5" className="mb-20 mt-40">
                    No Sale or Leasing of Your Information
                </Typography>
                <Typography variant="body1" gutterBottom>
                    We will not sell or lease your personal information to any
                    third party. We may disclose aggregate demographic and
                    statistical information with our business partners, but this
                    information is not specific to the identification of you as
                    an individual.
                </Typography>
                <Typography variant="h5" className="mb-20 mt-40">
                    Generally
                </Typography>
                <Typography variant="body1" gutterBottom>
                    We may disclose information with third parties, for limited
                    purposes, as follows:
                </Typography>
                <ul>
                    <li className="mb-20">
                        <strong>
                            Your information to our service providers.
                        </strong>{" "}
                        We use service providers who help us to provide you with
                        our services. We give some personnel of these providers
                        access to your information, but only to the extent
                        necessary for them to perform their services for us. Our
                        contracts with our service providers require them to
                        maintain technical protections to ensure the
                        confidentiality of your personal information and data,
                        to use it only for the provision of their services to
                        us, and to handle it in accordance with this privacy
                        policy. Examples of service providers include payment
                        processors, hosting services, email service providers,
                        and web traffic analytics tools.
                    </li>
                    <li className="mb-20">
                        <strong>
                            Your account details to your billing contact.
                        </strong>{" "}
                        If your account holder details are different from the
                        billing contact listed for your account, we may disclose
                        your identity and account details to the billing contact
                        upon their request. We typically will attempt to notify
                        you of such requests. By using our services and agreeing
                        to this privacy policy, you consent to this disclosure.
                    </li>
                    <li className="mb-20">
                        <strong>
                            Your email address to your organization.
                        </strong>{" "}
                        If the email address under which you’ve registered your
                        account belongs to or is controlled by an organization,
                        we may disclose that email address to that organization
                        in order to help it understand who associated with that
                        organization uses our services, and to assist the
                        organization with its business accounts.
                    </li>
                    <li className="mb-20">
                        <strong>
                            Aggregated or de-identified (anonymized) information
                            to third parties to improve or promote our services.
                        </strong>
                        We do this so that no individuals can reasonably be
                        identified or linked to any part of the information we
                        share with third parties to improve or promote our
                        services.
                    </li>
                    <li className="mb-20">
                        <strong>
                            The presence of a cookie to advertise our services.
                        </strong>{" "}
                        We may ask advertising networks and exchanges to display
                        ads promoting our services on other websites. We may ask
                        such parties to deliver those ads based on the presence
                        of a cookie that was placed when you visited one of our
                        websites, but in doing so we will not share any other
                        personal information with the advertiser. Our
                        advertising network partners may also use cookies and
                        page tags or web beacons to collect certain non-personal
                        information about your activities on this and other
                        websites to provide you with targeted advertising based
                        upon your interests.
                    </li>
                    <li className="mb-20">
                        <strong>
                            Your information if required or permitted by law.
                        </strong>{" "}
                        We may disclose your information as required or
                        permitted by law, or when we believe that disclosure is
                        necessary to protect our rights, protect your safety or
                        the safety of others, and/or to comply with a judicial
                        proceeding, court order, subpoena, or other legal
                        process served on us.
                    </li>
                    <li className="mb-20">
                        <strong>
                            Your information if there’s a change in business
                            ownership or structure.
                        </strong>
                        If ownership of all or substantially all of our business
                        changes, or we undertake a corporate reorganization,
                        including a merger, acquisition or consolidation or any
                        other action or transfer between Toggles entities, you
                        expressly consent to Toggles transferring your
                        information to the new owner or successor entity so that
                        we can continue providing our services.
                    </li>
                    <li className="mb-20">
                        <strong>
                            Information you expressly consent to be shared.
                        </strong>{" "}
                        For example, Toggles may expressly request your
                        permission to provide your contact details to third
                        parties for various purposes, including to allow those
                        third parties to contact you for marketing purposes. If
                        you give your permission, you may later revoke your
                        permission, but if you wish to stop receiving
                        communications from a third party to which we provided
                        your information with your permission, you will need to
                        contact that third party directly.
                    </li>
                </ul>
                <Typography variant="body1" gutterBottom>
                    Please note that, if you’re a Customer who has entrusted us
                    with safeguarding the privacy of your personal information,
                    we will not disclose or share it with third parties unless
                    we have (a) given you notice, such as in this privacy
                    policy, (b) obtained your express consent, such as through
                    an opt-in checkbox, or (c) de-identified or aggregated the
                    information so that individuals or other entities cannot
                    reasonably be identified by it. Where required by law to
                    disclose your information, we will notify you of this before
                    disclosing it.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    By using our services or visiting our websites, you consent
                    to the above-described disclosures.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    In some cases, the applications or user interfaces you
                    encounter while on our sites are managed by third parties,
                    who may require that you provide your personal information.
                    We are not responsible for the privacy practices of these
                    third party services or applications. We recommend carefully
                    reviewing the user terms and privacy statement of each third
                    party service, website, and/or application prior to use.
                </Typography>
                <Typography variant="h4" className="mb-40 mt-80">
                    HOW LONG WE RETAIN YOUR INFORMATION
                </Typography>
                <Typography variant="body1" gutterBottom>
                    We generally retain your information for as long as you have
                    an account with us, or as long as necessary to otherwise to
                    provide services to you, to comply with our legal
                    obligations, resolve disputes, or comply with and enforce
                    our agreements. Data that is deleted from our servers may
                    remain as residual copies on offsite backup media for up to
                    approximately one month afterward.
                </Typography>
                <Typography variant="h4" className="mb-40 mt-80">
                    REQUESTS TO DELETE, AMEND OR WITHDRAW CONSENT – NON-EEA OR
                    UK RESIDENTS
                </Typography>
                <Typography variant="body1" gutterBottom>
                    You may be entitled to request that we delete or amend your
                    personal information. You may also be entitled to withdraw
                    your consent, when consent is the basis for processing your
                    personal information.
                </Typography>

                <Typography variant="h5" className="mb-20 mt-40">
                    Legal Basis for Use of Your Information
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Personal information that we collect is processed under the
                    following legal basis:
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <em>Our legitimate interests</em>. This includes:
                </Typography>
                <ul>
                    <li className="mb-20">
                        to enable us to provide our products and services and
                        website use and access to you
                    </li>
                    <li className="mb-20">
                        for analytics, to gather metrics to better understand
                        how users use the our websites, and to evaluate and
                        improve our websites
                    </li>
                    <li className="mb-20">
                        to prevent fraud and other illegal activity
                    </li>
                    <li className="mb-20">
                        the legitimate interests of others (for example, to
                        ensure the security of our website)
                    </li>
                    <li className="mb-20">
                        to comply with legal obligations, as part of our general
                        business operations, and for other internal business
                        administration purposes
                    </li>
                    <li className="mb-20">
                        if we collect demographic information from you (such as
                        gender and ethnic origin) in order to carry out
                        diversity monitoring and such information is not
                        collected in an anonymous format, then we rely on our
                        legitimate interest to do so.
                    </li>
                </ul>
                <Typography variant="body1" gutterBottom>
                    <em>Contractual obligations</em>. For the performance of
                    contractual obligations between you and Toggles, including
                    the Toggles Terms of Use and/or in our separate written
                    contract with you.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <em>Consent</em>. Where required by law, we may process your
                    personal information in some cases for marketing purposes on
                    the basis of your consent (which you may withdraw at any
                    time after giving it, as described in this privacy policy).
                </Typography>
                <Typography variant="h5" className="mb-20 mt-40">
                    Your Rights
                </Typography>
                <Typography variant="h5" className="mb-20 mt-40">
                    Deletion of Personal Information
                </Typography>
                <Typography variant="body1" gutterBottom>
                    You may be entitled to request that we delete your personal
                    information in certain specific circumstances. If you wish
                    to exercise this right, please contact our Customer Support
                    Team. We will consider all such requests and provide our
                    response within a reasonable period (but no longer than one
                    calendar month from our receipt of your request unless we
                    tell you that we are entitled to a longer period under
                    applicable law). We may require you to verify your identity
                    before we respond to your request. Certain personal
                    information may be exempt from such requests in certain
                    circumstances, including as provided for in this privacy
                    policy.
                </Typography>
                <Typography variant="h5" className="mb-20 mt-40">
                    Access, Update, Data Portability and Other Rights
                </Typography>
                <Typography variant="body1" gutterBottom>
                    You may also be entitled to access your information, update
                    your personal information which is out of date or incorrect,
                    restrict use of your personal information in certain
                    specific circumstances, place a data portability request
                    (applicable only when we use your personal information on
                    the basis of your consent or performance of a contract, and
                    where our use of your information is carried out by
                    automated means), and ask us to consider any valid
                    objections which you have to our use of your personal
                    information where we process it on the basis of our or
                    another person’s legitimate interest. Requests should be
                    directed our Customer Support Team.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    We will consider all such requests and provide our response
                    within a reasonable period (but no longer than one calendar
                    month from our receipt of your request unless we tell you we
                    are entitled to a longer period under applicable law). We
                    may require you to verify your identity before we respond to
                    any of your requests. Certain personal information may be
                    exempt from such requests in certain circumstances,
                    including as provided for in this privacy policy.
                </Typography>
                <Typography variant="h5" className="mb-20 mt-40">
                    Children’s Privacy
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Use of our websites is intended for adults at least eighteen
                    (18) years of age. We do not knowingly collect
                    personally-identifying information from children under the
                    age of thirteen (13).
                </Typography>

                <Typography variant="h4" className="mb-40 mt-80">
                    HOW TO CONTACT US REGARDING YOUR PERSONAL INFORMATION
                </Typography>
                <Typography variant="body1" gutterBottom>
                    If you have any questions about this privacy policy, you may
                    contact our Customer Support Team.
                </Typography>

                <Typography variant="body1" gutterBottom>
                    Last Update: April 10, 2024
                </Typography>
            </div>
        </Container>
    );
}

function Metadata() {
    return (
        <Helmet>
            <title>Toggles Privacy Policy | Email Productivity Add-On</title>
            <meta
                name="description"
                content="Learn about the privacy practices and data handling policies of Toggles. Your privacy and security are our top priorities. Read our privacy policy to understand how we protect your information."
            />
            <meta property="og:url" content="https://www.gettoggles.com" />
            <meta property="og:type" content="website" />
            <meta
                property="og:title"
                content="Toggles Privacy Policy | Email Productivity Add-On"
            />
            <meta
                property="og:description"
                content="Learn about the privacy practices and data handling policies of Toggles. Your privacy and security are our top priorities. Read our privacy policy to understand how we protect your information."
            />
            <meta
                property="og:image"
                content="https://www.gettoggles.com/graphics/meta-fb-image-1200x630.jpg"
            />
            <meta name="twitter:site" content="@togglesforemail" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="twitter:title"
                content="Toggles Privacy Policy | Email Productivity Add-On"
            />
            <meta
                name="twitter:description"
                content="Learn about the privacy practices and data handling policies of Toggles. Your privacy and security are our top priorities. Read our privacy policy to understand how we protect your information."
            />
            <meta
                name="twitter:image"
                content="https://www.gettoggles.com/graphics/meta-twitter-image-1600x900.jpg"
            />
            <link rel="canonical" href="https://www.gettoggles.com/privacy" />

            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Privacy Policy",
                        "url": "https://www.gettoggles.com/privacy",
                        "description": "Review Toggles' Privacy Policy to understand how we collect, use, and protect your data.",
                        "mainEntity": {
                            "@type": "CreativeWork",
                            "name": "Privacy Policy",
                            "about": "This document explains Toggles' policies regarding the collection, use, and disclosure of personal data when you use our website and services."
                        }
                    }
                `}
            </script>
        </Helmet>
    );
}
