import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import CampaignIcon from "@mui/icons-material/Campaign";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import GavelIcon from "@mui/icons-material/Gavel";
import Groups3Icon from "@mui/icons-material/Groups3";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import Demo from "../components/Demo";
import outlookIcon from "../assets/outlook-icon.png";
import subjectTagsGraphic from "../assets/subject-tag-feature2.png";
import recipientGroupsGraphic from "../assets/recipients-feature.png";
import attachmentsFeature from "../assets/attachments-feature.png";
import multipleActionsGraphic from "../assets/multiple-actions-feature.png";
import templatesFeatureGraphic from "../assets/templates-feature.png";
import signatureFeatureGraphic from "../assets/signature-feature.png";
import solutionsCtaGraphic from "../assets/solutions-hr-cta.png";

import "./Home.scss";

export default function Home() {    
    return (
        <Container maxWidth="lg" className="home-container">
            <Metadata />

            {/* HERO */}
            <div className="home-hero">
                <ul className="circles blue">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
                <Box className="hero-box">
                    <Typography
                        className="hero-title animated-charcter"
                        variant="h1"
                        component={"h1"}
                        align="center"
                    >
                        Supercharge your email efficiency
                    </Typography>
                    <Typography
                        className="hero-description"
                        variant="h4"
                        component={"h2"}
                        align="center"
                    >
                        Spend less time on repetitive emails and more time on
                        what truly matters.
                    </Typography>
                    <div className="hero-buttons">
                        <Button
                            className="hero-btn"
                            variant="outlined"
                            size="large"
                            href="https://hub.togglesforemail.com/signup?type=premium"
                        >
                            Start free trial
                        </Button>
                        <Button
                            className="hero-btn"
                            variant="contained"
                            size="large"
                            href="/request-demo"
                        >
                            Schedule a demo
                        </Button>
                    </div>
                </Box>
            </div>

            <div className="home-demo-container section full-width">
                <Container maxWidth="lg">
                    <Typography
                        className="section-title fwidth"
                        variant="h2"
                        align="center"
                    >
                        Discover time-saving email workflows powered by Toggles
                    </Typography>

                    <Demo />
                </Container>
            </div>

            {/* FEATURES */}
            <div className="home-features section full-width">
                <Container maxWidth="xl">
                    <Typography
                        className="section-title"
                        variant="h2"
                        align="center"
                    >
                        Unmatched features.{" "}
                        <span className="animated-color">Your rules.</span>
                    </Typography>
                    <Grid
                        className="features-grid"
                        container
                        rowSpacing={{ xs: 2, sm: 4, md: 6 }}
                        columnSpacing={{ xs: 2, sm: 4, md: 6 }}
                    >
                        <Grid className="feature-box" xs={12} md={6} xl={4}>
                            <div className="feature-box-container">
                                <img
                                    className="feature-img"
                                    src={templatesFeatureGraphic}
                                    alt="Toggles Template Feature Graphic"
                                />
                                <Typography
                                    className="feature-label"
                                    variant="h3"
                                    align="center"
                                >
                                    Use templates and stop typing the same email
                                    over and over
                                </Typography>
                            </div>
                        </Grid>
                        <Grid className="feature-box" xs={12} md={6} xl={4}>
                            <div className="feature-box-container">
                                <img
                                    className="feature-img"
                                    src={signatureFeatureGraphic}
                                    alt="Toggles Signature Feature Graphic"
                                />
                                <Typography
                                    className="feature-label"
                                    variant="h3"
                                    align="center"
                                >
                                    Quickly insert your custom signature with a
                                    simple toggle
                                </Typography>
                            </div>
                        </Grid>
                        <Grid className="feature-box" xs={12} md={6} xl={4}>
                            <div className="feature-box-container">
                                <img
                                    className="feature-img"
                                    src={subjectTagsGraphic}
                                    alt="Toggles Tagging Feature Graphic"
                                />
                                <Typography
                                    className="feature-label"
                                    variant="h3"
                                    align="center"
                                >
                                    Add tags to the subject with the flip of a
                                    toggle
                                </Typography>
                            </div>
                        </Grid>
                        <Grid className="feature-box" xs={12} md={6} xl={4}>
                            <div className="feature-box-container">
                                <img
                                    className="feature-img"
                                    src={recipientGroupsGraphic}
                                    alt="Toggles Recipient Groups Graphic"
                                />
                                <Typography
                                    className="feature-label"
                                    variant="h3"
                                    align="center"
                                >
                                    Instantly add predefined recipient groups
                                    for any email
                                </Typography>
                            </div>
                        </Grid>
                        <Grid className="feature-box" xs={12} md={6} xl={4}>
                            <div className="feature-box-container">
                                <img
                                    className="feature-img"
                                    src={attachmentsFeature}
                                    alt="Toggles Attachments Feature Graphic"
                                />
                                <Typography
                                    className="feature-label"
                                    variant="h3"
                                    align="center"
                                >
                                    Attach common documents with just a toggle
                                </Typography>
                            </div>
                        </Grid>
                        <Grid className="feature-box" xs={12} md={6} xl={4}>
                            <div className="feature-box-container">
                                <img
                                    className="feature-img"
                                    src={multipleActionsGraphic}
                                    alt="Toggles Multiple Actions Feature Graphic"
                                />
                                <Typography
                                    className="feature-label"
                                    variant="h3"
                                    align="center"
                                >
                                    Combine actions to create time saving
                                    workflows
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <div className="home-cta split-cta full-width light reverse">
                <Container maxWidth="lg">
                    <Grid container>
                        <Grid xs={12} md={4} className="graphic-container">
                            <img
                                src={solutionsCtaGraphic}
                                alt="Toggles for Outlook - Human Resources Graphic"
                            />
                        </Grid>
                        <Grid xs={12} md={8} className="text-content">
                            <Typography
                                className="text-title mb-30"
                                variant="h3"
                            >
                                Revolutionize your email productivity
                            </Typography>
                            <Typography
                                className="text-description"
                                variant="body1"
                            >
                                Toggles makes it easy to streamline your email
                                workflows, automate repetitive tasks, and save
                                valuable time. Whether you're managing your
                                inbox solo or collaborating with a team, Toggles
                                helps you work smarter, not harder.
                            </Typography>
                            <div className="cta-buttons">
                                <Button
                                    className="cta-button"
                                    variant="outlined"
                                    size="large"
                                    href="https://hub.togglesforemail.com/signup?type=premium"
                                >
                                    Get started today
                                </Button>
                                <Button
                                    className="cta-button demo-request"
                                    variant="outlined"
                                    size="large"
                                    href="/teams"
                                >
                                    Explore Team Features
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <div className="section full-width use-cases">
                <Typography
                    className="section-title"
                    variant="h2"
                    align="center"
                >
                    <span className="animated-color">Productivity</span> for
                    those who want more.
                </Typography>
                <Container maxWidth="xl">
                    <Grid
                        container
                        spacing={6}
                        className="container"
                        alignItems="stretch"
                    >
                        <Grid
                            xs={12}
                            sm={6}
                            lg={3}
                            style={{ display: "flex" }}
                            className="use-case"
                        >
                            <Card raised sx={{ borderRadius: "40px" }}>
                                <Avatar
                                    sx={{
                                        bgcolor: "#1b568e",
                                        width: "120px",
                                        height: "120px",
                                        margin: "30px auto 10px auto",
                                    }}
                                >
                                    <MonetizationOnOutlinedIcon
                                        sx={{ fontSize: "4.5rem" }}
                                    />
                                </Avatar>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        textAlign="center"
                                    >
                                        Sales
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary" }}
                                    >
                                        Automate follow-ups, proposals, and
                                        client outreach to boost productivity,
                                        close deals faster, and focus on
                                        building relationships.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            lg={3}
                            style={{ display: "flex" }}
                            className="use-case"
                        >
                            <Card raised sx={{ borderRadius: "40px" }}>
                                <Avatar
                                    sx={{
                                        bgcolor: "#1b568e",
                                        width: "120px",
                                        height: "120px",
                                        margin: "30px auto 10px auto",
                                    }}
                                >
                                    <SupportAgentOutlinedIcon
                                        sx={{ fontSize: "4.5rem" }}
                                    />
                                </Avatar>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        textAlign="center"
                                    >
                                        Customer Support
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary" }}
                                    >
                                        Respond to repetitive inquiries, send
                                        case updates, and improve response times
                                        by automating routine emails and
                                        communications.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            lg={3}
                            style={{ display: "flex" }}
                            className="use-case"
                        >
                            <Card raised sx={{ borderRadius: "40px" }}>
                                <Avatar
                                    sx={{
                                        bgcolor: "#1b568e",
                                        width: "120px",
                                        height: "120px",
                                        margin: "30px auto 10px auto",
                                    }}
                                >
                                    <Diversity3Icon
                                        sx={{ fontSize: "4.5rem" }}
                                    />
                                </Avatar>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        textAlign="center"
                                    >
                                        Human Resources
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary" }}
                                    >
                                        Streamline employee onboarding,
                                        interview scheduling, and policy updates
                                        with automated emails, saving time and
                                        reducing manual tasks.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            lg={3}
                            style={{ display: "flex" }}
                            className="use-case"
                        >
                            <Card raised sx={{ borderRadius: "40px" }}>
                                <Avatar
                                    sx={{
                                        bgcolor: "#1b568e",
                                        width: "120px",
                                        height: "120px",
                                        margin: "30px auto 10px auto",
                                    }}
                                >
                                    <CampaignIcon sx={{ fontSize: "4.5rem" }} />
                                </Avatar>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        textAlign="center"
                                    >
                                        Marketing
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary" }}
                                    >
                                        Automate email campaigns, follow-ups,
                                        and newsletters to streamline your
                                        communication strategy and free up time
                                        for creative tasks.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            lg={3}
                            style={{ display: "flex" }}
                            className="use-case"
                        >
                            <Card raised sx={{ borderRadius: "40px" }}>
                                <Avatar
                                    sx={{
                                        bgcolor: "#1b568e",
                                        width: "120px",
                                        height: "120px",
                                        margin: "30px auto 10px auto",
                                    }}
                                >
                                    <GavelIcon sx={{ fontSize: "4.5rem" }} />
                                </Avatar>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        textAlign="center"
                                    >
                                        Legal
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary" }}
                                    >
                                        Reduce time spent on case updates,
                                        contract renewals, and compliance
                                        reminders by automating routine legal
                                        communication.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            lg={3}
                            style={{ display: "flex" }}
                            className="use-case"
                        >
                            <Card raised sx={{ borderRadius: "40px" }}>
                                <Avatar
                                    sx={{
                                        bgcolor: "#1b568e",
                                        width: "120px",
                                        height: "120px",
                                        margin: "30px auto 10px auto",
                                    }}
                                >
                                    <Groups3Icon sx={{ fontSize: "4.5rem" }} />
                                </Avatar>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        textAlign="center"
                                    >
                                        Management
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary" }}
                                    >
                                        Automate project updates, task
                                        reminders, and meeting invitations,
                                        helping you manage teams and projects
                                        with increased efficiency.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            lg={3}
                            style={{ display: "flex" }}
                            className="use-case"
                        >
                            <Card raised sx={{ borderRadius: "40px" }}>
                                <Avatar
                                    sx={{
                                        bgcolor: "#1b568e",
                                        width: "120px",
                                        height: "120px",
                                        margin: "30px auto 10px auto",
                                    }}
                                >
                                    <ManageAccountsIcon
                                        sx={{ fontSize: "4.5rem" }}
                                    />
                                </Avatar>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        textAlign="center"
                                    >
                                        IT & Tech Support
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary" }}
                                    >
                                        Simplify IT notifications, system
                                        updates, and support communications by
                                        automating repetitive emails, allowing
                                        more focus on complex tasks.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            lg={3}
                            style={{ display: "flex" }}
                            className="use-case"
                        >
                            <Card raised sx={{ borderRadius: "40px" }}>
                                <Avatar
                                    sx={{
                                        bgcolor: "#1b568e",
                                        width: "120px",
                                        height: "120px",
                                        margin: "30px auto 10px auto",
                                    }}
                                >
                                    <LightbulbOutlinedIcon
                                        sx={{ fontSize: "4.5rem" }}
                                    />
                                </Avatar>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        textAlign="center"
                                    >
                                        Consulting
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary" }}
                                    >
                                        Automate client check-ins, status
                                        updates, and proposal deliveries to
                                        maintain consistent communication and
                                        improve client satisfaction.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <div className="section full-width cta-curved">
                <Typography
                    className="section-title"
                    variant="h2"
                    align="center"
                >
                    Simplify your email, supercharge your workflow
                </Typography>
                <div className="cta-buttons">
                    <Button
                        className="cta-btn contained"
                        variant="outlined"
                        size="large"
                        href="https://hub.togglesforemail.com/signup?type=premium"
                    >
                        Get started
                    </Button>
                    <Button
                        className="cta-btn outlined"
                        variant="outlined"
                        size="large"
                        href="/request-demo"
                    >
                        Talk to sales
                    </Button>
                </div>
            </div>
        </Container>
    );
};

function Metadata() {
    return (
        <Helmet>
            <title>
                Toggles | Boost Email Productivity with Powerful Automation
                Tools
            </title>
            <meta
                name="description"
                content="Boost your email productivity with Toggles. Streamline your email workflow, automate repetitive tasks, and save time with powerful one-click email automation tools."
            />
            <meta
                name="keywords"
                content="email productivity tools, email automation, workflow automation, email management software, automate email workflows, email task automation, Toggles for email automation"
            />

            <meta
                itemProp="name"
                content="Toggles | Boost Email Productivity with Powerful Automation Tools"
            />
            <meta
                itemProp="description"
                content="Boost your email productivity with Toggles. Streamline your email workflow, automate repetitive tasks, and save time with powerful one-click email automation tools."
            />
            <meta
                itemProp="image"
                content="http://www.gettoggles.com/graphics/meta-fb-image-1200x630.jpg"
            />

            <meta property="og:url" content="https://www.gettoggles.com" />
            <meta property="og:type" content="website" />
            <meta
                property="og:title"
                content="Toggles | Boost Email Productivity with Powerful Automation Tools"
            />
            <meta
                property="og:description"
                content="Boost your email productivity with Toggles. Streamline your email workflow, automate repetitive tasks, and save time with powerful one-click email automation tools."
            />
            <meta
                property="og:image"
                content="https://www.gettoggles.com/graphics/meta-fb-image-1200x630.jpg"
            />

            <meta name="twitter:site" content="@togglesforemail" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="twitter:title"
                content="Toggles | Boost Email Productivity with Powerful Automation Tools"
            />
            <meta
                name="twitter:description"
                content="Boost your email productivity with Toggles. Streamline your email workflow, automate repetitive tasks, and save time with powerful one-click email automation tools."
            />
            <meta
                name="twitter:image"
                content="https://www.gettoggles.com/graphics/meta-twitter-image-1600x900.jpg"
            />

            <link rel="canonical" href="https://www.gettoggles.com/" />

            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "Product",
                        "name": "Toggles Email Productivity Tool",
                        "description": "Boost your email productivity with Toggles. Automate your email workflows, streamline communication, and save time with powerful one-click automation tools.",
                        "url": "https://www.gettoggles.com",
                        "image": "https://www.gettoggles.com/graphics/meta-fb-image-1200x630.jpg",
                        "offers": [
                            {
                                "@type": "Offer",
                                "url": "https://www.gettoggles.com/pricing#starter",
                                "priceCurrency": "USD",
                                "price": "0.00",
                                "priceValidUntil": "2025-12-31",
                                "eligibleRegion": "US",
                                "name": "Starter Plan",
                                "description": "Free plan with limited functionality for individuals just getting started with email automation."
                            },
                            {
                                "@type": "Offer",
                                "url": "https://www.gettoggles.com/pricing#premium-plus-monthly",
                                "priceCurrency": "USD",
                                "price": "12.50",
                                "priceValidUntil": "2025-12-31",
                                "eligibleRegion": "US",
                                "priceSpecification": {
                                    "@type": "PriceSpecification",
                                    "priceCurrency": "USD",
                                    "price": "12.50",
                                    "priceType": "Subscription",
                                    "billingDuration": "P1M"
                                },
                                "name": "Premium Plus (Monthly)",
                                "description": "Monthly plan for individuals with full access to premium features and email automation tools."
                            },
                            {
                                "@type": "Offer",
                                "url": "https://www.gettoggles.com/pricing#premium-plus-annual",
                                "priceCurrency": "USD",
                                "price": "120.00",
                                "priceValidUntil": "2025-12-31",
                                "eligibleRegion": "US",
                                "priceSpecification": {
                                    "@type": "PriceSpecification",
                                    "priceCurrency": "USD",
                                    "price": "120.00",
                                    "priceType": "Subscription",
                                    "billingDuration": "P1Y"
                                },
                                "name": "Premium Plus (Annual)",
                                "description": "Annual plan for individuals offering savings and access to advanced email automation tools."
                            },
                            {
                                "@type": "Offer",
                                "url": "https://www.gettoggles.com/pricing#business-pro-monthly",
                                "priceCurrency": "USD",
                                "price": "19.00",
                                "priceValidUntil": "2025-12-31",
                                "eligibleRegion": "US",
                                "priceSpecification": {
                                    "@type": "PriceSpecification",
                                    "priceCurrency": "USD",
                                    "price": "19.00",
                                    "priceType": "Subscription",
                                    "billingDuration": "P1M"
                                },
                                "name": "Business Pro (Monthly)",
                                "description": "Monthly plan for teams with advanced email automation tools, team collaboration, and enhanced functionality."
                            },
                            {
                                "@type": "Offer",
                                "url": "https://www.gettoggles.com/pricing#business-pro-annual",
                                "priceCurrency": "USD",
                                "price": "180.00",
                                "priceValidUntil": "2025-12-31",
                                "eligibleRegion": "US",
                                "priceSpecification": {
                                    "@type": "PriceSpecification",
                                    "priceCurrency": "USD",
                                    "price": "180.00",
                                    "priceType": "Subscription",
                                    "billingDuration": "P1Y"
                                },
                                "name": "Business Pro (Annual)",
                                "description": "Annual plan for teams with access to full features, customizable email automation, and priority support."
                            }
                        ]
                    }
                `}
            </script>
        </Helmet>
    );
}
