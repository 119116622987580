// @ts-nocheck
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Typography from "@mui/material/Typography";
import Chip from '@mui/material/Chip';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Alert from "@mui/material/Alert";
import ReactGA from "react-ga4";

import RuleToggle from "./RuleToggle";
import { DEMO_RULES, DEMO_USER_TYPES } from "./demorules";
import ActionsList from "./ActionsList";

import "./styles.scss";

const DEFAULT_MESSAGE_DATA = {
    to: "",
    cc: "",
    bcc: "",
    subject: "",
    body: "",
    delay: 0,
    attachments: [],
};

function titleCase(string) {
    if (string === 'hr') return 'HR';

    return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

function Confetti() {
    return (
        <div className="confetti">
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
        </div>
    );
};

export default function Demo({ userTypes = DEMO_USER_TYPES, showTypeOptions = true, showTimeSavings = true }) {
    const [userType, setUserType] = useState(userTypes[0].name);
    const [isSending, setIsSending] = useState(false);
    const [messageRules, setMessageRules] = useState([]);
    const [messageData, setMessageData] = useState(DEFAULT_MESSAGE_DATA);
    const [ruleManualTime, setRuleManualTime] = useState(0);
    const [ruleTogglesTime, setRuleTogglesTime] = useState(0);

    const demoSend = () => {
        setIsSending(true);
        setTimeout(() => {
            setIsSending(false);
            setMessageRules([]);
            setMessageData(DEFAULT_MESSAGE_DATA);
        }, 3000);

        ReactGA.event({
            category: "Button Click",
            action: "Demo Send Clicked",
            label: "Demo",
        });
    };

    const processRule = (rule, enabled) => {
        switch (rule.metadata.trigger) {
            case "toggle":
                rule.metadata.actions.forEach((action) => {
                    switch (action.type) {
                        case "tag":
                            handleSubjectRule(action, enabled);
                            break;
                        case "recipient":
                            handleRecipientRule(action, enabled);
                            break;
                        case "attachment":
                            handleAttachmentRule(action, enabled);
                            break;
                        case "link":
                            handleLinkRule(action, enabled);
                            break;
                        case "template":
                            handleTemplateRule(action, enabled);
                            break;
                        case "signature":
                            handleSignatureRule(action, enabled);
                            break;
                        case 'delay':
                            handleDelayRule(action, enabled);
                            break;
                        default:
                            break;
                    }
                });
                break;
            default:
                break;
        }
    };

    const handleSubjectRule = (action, enabled) => {
        if (enabled) {
            setMessageData((prevData) => ({
                ...prevData,
                subject: `${action.tag} ${prevData.subject}`,
            }));
        } else {
            setMessageData((prevData) => ({
                ...prevData,
                subject: prevData.subject.replace(action.tag, "").trim(),
            }));
        }
    };

    const handleRecipientRule = (action, enabled) => {
        if (enabled) {
            setMessageData((prevData) => ({
                ...prevData,
                [action.recipientType]: [
                    ...prevData[action.recipientType],
                    ...action.emails,
                ].join(", "),
            }));
        } else {
            setMessageData((prevData) => ({
                ...prevData,
                [action.recipientType]: prevData[action.recipientType]
                    .split(", ")
                    .filter((email) => !action.emails.includes(email))
                    .join(", "),
            }));
        }
    };

    const handleAttachmentRule = (action, enabled) => {
        if (enabled) {
            setMessageData((prevData) => ({
                ...prevData,
                attachments: [...prevData.attachments, ...action.attachments],
            }));
        } else {
            setMessageData((prevData) => ({
                ...prevData,
                attachments: prevData.attachments.filter(
                    (att) => !action.attachments.includes(att)
                ),
            }));
        }
    };

    const handleLinkRule = (action, enabled) => {
        if (enabled) {
            setMessageData((prevData) => ({
                ...prevData,
                body: `${prevData.body}\n<a href="${action.url}">${action.text}</a>`,
            }));
        } else {
            setMessageData((prevData) => ({
                ...prevData,
                body: prevData.body.replace(
                    `\n<a href="${action.url}">${action.text}</a>`,
                    ""
                ),
            }));
        }
    };

    const handleTemplateRule = (action, enabled) => {
        if (enabled) {
            setMessageData((prevData) => ({
                ...prevData,
                body: `${action.template.html_content}`,
            }));
        } else {
            setMessageData((prevData) => ({
                ...prevData,
                body: prevData.body.replace(
                    `${action.template.html_content}`,
                    ""
                ),
            }));
        }
    };

    const handleSignatureRule = (action, enabled) => {
        if (enabled) {
            setMessageData((prevData) => ({
                ...prevData,
                signature: action.signature.html_content,
            }));
        } else {
            setMessageData((prevData) => ({
                ...prevData,
                signature: "",
            }));
        }
    };

    const handleDelayRule = (action, enabled) => {
        if (enabled) {
            // Delay sending
            setMessageData((prevData) => ({
                ...prevData,
                delay: action.mockDelayDays,
            }));
        } else {
            // Cancel delay
            setMessageData((prevData) => ({
                ...prevData,
                delay: 0,
            }));
        }
    };

    const handleRuleToggle = (rule, enabled) => {
        // Check if rule being enabled can be combined with another rule
        // If not, then disable all other rules first
        const hasExclusive = messageRules.some((id) => {
            const r = DEMO_RULES[userType].find((rule) => rule.id === id);
            return r.metadata.exclusive;
        });

        if ((enabled && (rule.metadata.exclusive) || hasExclusive)) {
            DEMO_RULES[userType].forEach((r) => {
                if (r.id !== rule.id && messageRules.includes(r.id)) {
                    processRule(r, false);
                }
            });
        }
        processRule(rule, enabled);

        setMessageRules((prevRules) => {
            if (enabled) {
                if (!prevRules.includes(rule.id)) {
                    if (rule.metadata.exclusive) {
                        return [rule.id];
                    }

                    // Check if prevRules contains any exclusive rules
                    const hasExclusive = prevRules.some((id) => {
                        const r = DEMO_RULES[userType].find((rule) => rule.id === id);
                        return r.metadata.exclusive;
                    });

                    return hasExclusive ? [rule.id] : [...prevRules, rule.id];
                } else {
                    return [...prevRules];
                }
            } else {
                if (prevRules.includes(rule.id)) {
                    return prevRules.filter((id) => id !== rule.id);
                } else {
                    return [...prevRules];
                }
            }
        });
    };

    const getEmailBodyAndSig = () => {
        let body = messageData.body || "<p>Type your message here...</p>";
        let signature = messageData.signature || "";

        return `${body}\n<br />${signature}`;
    };

    const getTimeSaving = () => {
        return ruleManualTime - ruleTogglesTime;
    };

    const formatSecondstoMinAndSeconds = (seconds) => {
        let minutes = Math.floor(seconds / 60);
        let remainingSeconds = seconds % 60;

        // Handle pluralization and omission of minutes if equal to 0
        const minuteText = minutes === 1 ? "minute" : "minutes";
        const secondText = remainingSeconds === 1 ? "second" : "seconds";

        // Return formatted string
        if (minutes === 0) {
            return `${remainingSeconds} ${secondText}`;
        } else if (remainingSeconds === 0) {
            return `${minutes} ${minuteText}`;
        } else {
            return `${minutes} ${minuteText} and ${remainingSeconds} ${secondText}`;
        }
    };

    const generateTimeSavingsMessage = (secondsSaved) => {
        const formattedTime = formatSecondstoMinAndSeconds(secondsSaved);
        return (
            <Typography variant="body2">
                This workflow can save you around{" "}
                <Typography component="span" fontWeight="bold">
                    {formattedTime}
                </Typography>{" "}
                per email when using Toggles.
            </Typography>
        );
    };

    useEffect(() => {
        setMessageRules([]);   
        setIsSending(false);
        setMessageData(DEFAULT_MESSAGE_DATA);     
    }, [userType]);

    useEffect(() => {
        // Add up time savings
        let manualTime = 0;
        let togglesTime = 0;

        messageRules.forEach((ruleId) => {
            const rule = DEMO_RULES[userType].find((r) => r.id === ruleId);
            manualTime += rule.manual_time;
            togglesTime += rule.toggles_time;
        });

        setRuleManualTime(manualTime);
        setRuleTogglesTime(togglesTime);
    }, [messageRules]);

    return (
        <>
            {showTypeOptions && (
                <Grid className="audience-container" container>
                    <Grid
                        className="audience-selection"
                        xs={10}
                        xsOffset={1}
                        lg={10}
                        lgOffset={1}
                    >
                        <Box className="user-type-container">
                            <ToggleButtonGroup
                                className="user-type-buttons"
                                color="primary"
                                value={userType}
                                exclusive
                                onChange={(ev, value) => {
                                    setUserType(value);
                                    ReactGA.event({
                                        category: "Button Click",
                                        action: `Audience ${value} Clicked`,
                                        label: "Demo",
                                    });
                                }}
                                aria-label="Platform"
                            >
                                {userTypes.map((type, idx) => (
                                    <ToggleButton
                                        className="user-type-button"
                                        key={idx}
                                        variant="contained"
                                        color="primary"
                                        value={type.name}
                                    >
                                        <div className="audience-item-container">
                                            {type.icon}
                                            <Typography
                                                className="audience-item-label"
                                                variant="button"
                                                align="center"
                                            >
                                                <span className="role-type-label">
                                                    {type.name}
                                                </span>{" "}
                                                Teams
                                            </Typography>
                                        </div>
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                        </Box>
                    </Grid>
                </Grid>
            )}

            <Grid
                className={`demo-container ${isSending && "is-sending"}`}
                container
            >
                <Grid
                    className="demo-grid-item"
                    xs={2}
                    lg={12}
                    order={{ xs: 2, lg: 1 }}
                >
                    <ActionsList
                        rules={DEMO_RULES[userType]}
                        enabledRules={messageRules}
                    />
                </Grid>
                <Grid xs={10} lg={12} container order={{ xs: 1, lg: 2 }}>
                    <Grid
                        className="demo-grid-item"
                        xs={12}
                        lg={6}
                        order={{ xs: 2, lg: 1 }}
                    >
                        <Box className="compose-container">
                            <Confetti />
                            <Button
                                variant="contained"
                                endIcon={<SendIcon />}
                                sx={{ mb: 1 }}
                                onClick={demoSend}
                                disabled={isSending}
                            >
                                Send
                            </Button>
                            {!!messageData.delay && messageData.delay > 0 && (
                                <Typography
                                    className="delay-message"
                                    variant="body1"
                                >
                                    Scheduled to send in {messageData.delay}{" "}
                                    {messageData.delay === 1 ? "day" : "days"}
                                </Typography>
                            )}
                            <TextField
                                fullWidth
                                disabled
                                multiline
                                sx={{ my: 1, color: "#fff" }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            To:
                                        </InputAdornment>
                                    ),
                                }}
                                value={messageData.to}
                            />
                            {messageData.cc && (
                                <TextField
                                    fullWidth
                                    disabled
                                    multiline
                                    sx={{ my: 1, color: "#fff" }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                Cc:
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={messageData.cc}
                                />
                            )}
                            {messageData.bcc && (
                                <TextField
                                    fullWidth
                                    disabled
                                    multiline
                                    sx={{ my: 1, color: "#fff" }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                Bcc:
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={messageData.bcc}
                                />
                            )}
                            <TextField
                                fullWidth
                                disabled
                                multiline
                                sx={{ my: 1, color: "#fff" }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            Subject:
                                        </InputAdornment>
                                    ),
                                }}
                                value={messageData.subject}
                            />
                            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                                {messageData.attachments.map(
                                    (attachment, idx) => (
                                        <Chip
                                            key={idx}
                                            icon={<AttachFileIcon />}
                                            label={attachment.name}
                                            sx={{ m: 0.5 }}
                                        />
                                    )
                                )}
                            </Box>

                            <div
                                className="email-body"
                                dangerouslySetInnerHTML={{
                                    __html: getEmailBodyAndSig(),
                                }}
                            ></div>

                            {(messageRules.length > 0 && ruleManualTime > 0) && (
                                <Alert severity="success" variant="filled" sx={{ mt: 2 }}>
                                    {generateTimeSavingsMessage(getTimeSaving())}
                                </Alert>
                            )}
                        </Box>
                    </Grid>
                    <Grid
                        className="demo-grid-item"
                        xs={12}
                        lg={6}
                        order={{ xs: 1, lg: 2 }}
                    >
                        <Box className="rules-container">
                            {userType && (
                                <Box className="type-rules-container">
                                    <Typography
                                        className="select-rules-title"
                                        variant="h5"
                                    >
                                        <span className="animated-color">
                                            {titleCase(userType)} Team Workflows
                                        </span>
                                    </Typography>
                                    <Box
                                        className={`rule-toggles-container ${userType}`}
                                    >
                                        {DEMO_RULES[userType].map(
                                            (rule, idx) => (
                                                <RuleToggle
                                                    key={idx}
                                                    rule={rule}
                                                    isEnabled={messageRules.includes(
                                                        rule.id
                                                    )}
                                                    onChange={(rule, enabled) =>
                                                        handleRuleToggle(
                                                            rule,
                                                            enabled
                                                        )
                                                    }
                                                />
                                            )
                                        )}
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
