import React, { useState } from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import togglesIcon from "../../assets/logo32.png";
import "./styles.scss";

const otherFeatures = [
    "Microsoft Outlook support",
    "Google Mail support (Soon)",
    "Multiple action rules",
    "Attachment action support",
    "Shared mailbox support",
    "User Groups",
    "Group Rules",
    "Organizational Rules",
    "Tutorial access",
    "Knowledge Base access",
    "Subject variables support (Soon)",
    "More features coming soon!",
];

function BusinessProPriceCard({ showButton = true, interval = "month" }) {
    const [featuresOpen, setFeaturesOpen] = useState(false);

    return (
        <div className="price-card-container">
            <Card className="price-card business" variant="outlined">
                <CardContent className="card-content">
                    <div className="card-title">
                        <img src={togglesIcon} alt="Toggles Logo" />
                        <Typography
                            className="title-text"
                            variant="h5"
                            component="div"
                        >
                            BUSINESS PRO
                        </Typography>
                    </div>
                    <Typography className="card-description" gutterBottom>
                        Unleash the productivity of your team with no
                        limitations, shared rules, and other advanced features
                    </Typography>
                    <Typography className="card-price" variant="h4">
                        <span className="number">
                            {interval === "month" ? "$19.00" : "$180"}
                        </span>{" "}
                        / {interval === "month" ? "month" : "year"} / user
                    </Typography>
                    <div className="card-features">
                        <div className="feature">
                            <CheckBoxOutlinedIcon className="feature-checkbox" />
                            <Typography
                                className="feature-text"
                                variant="subtitle2"
                                component="div"
                            >
                                All features & No limits!
                            </Typography>
                        </div>
                        <div className="feature">
                            <CheckBoxOutlinedIcon className="feature-checkbox" />
                            <Typography
                                className="feature-text"
                                variant="subtitle2"
                                component="div"
                            >
                                Advanced rule logic
                            </Typography>
                        </div>
                        <div className="feature">
                            <CheckBoxOutlinedIcon className="feature-checkbox" />
                            <Typography
                                className="feature-text"
                                variant="subtitle2"
                                component="div"
                            >
                                Templates & Signatures
                            </Typography>
                        </div>
                        <div className="feature">
                            <CheckBoxOutlinedIcon className="feature-checkbox" />
                            <Typography
                                className="feature-text"
                                variant="subtitle2"
                                component="div"
                            >
                                Group & Company Rules
                            </Typography>
                        </div>
                        <div className="feature">
                            <CheckBoxOutlinedIcon className="feature-checkbox" />
                            <Typography
                                className="feature-text"
                                variant="subtitle2"
                                component="div"
                            >
                                Full-service email support
                            </Typography>
                        </div>
                    </div>
                </CardContent>
                {showButton && (
                    <CardActions className="price-card-actions">
                        <Button fullWidth variant="contained" size="large">
                            Get Premium
                        </Button>
                    </CardActions>
                )}

                <Container
                    className="all-features"
                    sx={{ display: { xs: "block", md: "none" } }}
                >
                    <Button
                        className="toggle-all-features premium"
                        onClick={() => setFeaturesOpen((prev) => !prev)}
                        fullWidth
                        size="small"
                    >
                        All Features{" "}
                        {featuresOpen ? (
                            <ArrowDropUpIcon />
                        ) : (
                            <ArrowDropDownIcon />
                        )}
                    </Button>

                    {featuresOpen && (
                        <div className="card-features all">
                            {otherFeatures.map((feature, idx) => (
                                <div
                                    className="feature"
                                    key={`other-feature-${idx}`}
                                >
                                    <CheckBoxOutlinedIcon className="feature-checkbox" />
                                    <Typography
                                        className="feature-text"
                                        variant="subtitle2"
                                        component="div"
                                    >
                                        {feature}
                                    </Typography>
                                </div>
                            ))}
                        </div>
                    )}
                </Container>
            </Card>
            <Button
                className="price-start-button business"
                variant="outlined"
                fullWidth
                href="https://hub.togglesforemail.com/signup?type=business"
            >
                Start 14 Day Free Trial
            </Button>
        </div>
    );
}

export default BusinessProPriceCard;
