import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import ScrollToTop from './helpers/scrollToTop';
import theme from './theme';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

root.render(
    <BrowserRouter>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <ScrollToTop />
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <App />
            </ThemeProvider>
        </StyledEngineProvider>
    </BrowserRouter>
);
