import * as React from "react";
import { Helmet } from "react-helmet-async";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ROICalculator from "../components/ROICalculator";
import benefitsGraphic from "../assets/bp-benefits-graphic.png";

import "./ProductivityCalculator.scss";

export default function ProductivityCalculator() {
    return (
        <Container maxWidth="lg" className="productivity-calculator-container">
            <Metadata />
            <div className="page-hero">
                <Box className="hero-box">
                    <Typography
                        className="hero-title animated-color"
                        variant="h1"
                        align="center"
                    >
                        How much time and money can Toggles save your team?
                    </Typography>
                    <Typography
                        className="hero-subtitle"
                        variant="h3"
                        align="center"
                    >
                        Discover time and cost savings with Toggles. Instantly
                        estimate your team's productivity gains with our ROI
                        calculator.
                    </Typography>
                </Box>
            </div>

            <div className="section full-width feature benefits2 darkest">
                <Container maxWidth="xl">
                    <Grid container spacing={3} className="container">
                        <Grid xs={12} lg={6} className="text-content">
                            <Typography variant="h3" className="primary-title">
                                Communicate faster with less effort.
                            </Typography>
                            <Typography variant="body1" className="subtitle">
                                Toggles Business Pro helps organizations
                                streamline email communication and reduce email
                                overload.
                            </Typography>
                        </Grid>
                        <Grid xs={12} lg={6}>
                            <img
                                className="feature-img"
                                src={benefitsGraphic}
                                alt="Toggles benefits graphic - template toggle"
                            />
                        </Grid>
                        <Grid xs={12} lg={4} className="benefit">
                            <Typography variant="h4" className="title">
                                Accomplish more — faster
                            </Typography>
                            <Typography variant="body1" className="description">
                                Send personalized emails with a couple clicks to
                                maximize your team's time.
                            </Typography>
                        </Grid>
                        <Grid xs={12} lg={4} className="benefit">
                            <Typography variant="h4" className="title">
                                Deliver consistent messages
                            </Typography>
                            <Typography variant="body1" className="description">
                                Coordinate templates, attachments and workflows
                                across your team to streamline emails.
                            </Typography>
                        </Grid>
                        <Grid xs={12} lg={4} className="benefit">
                            <Typography variant="h4" className="title">
                                Automate with ease
                            </Typography>
                            <Typography variant="body1" className="description">
                                Create standardized emails and workflows quickly
                                without being a tech whiz.
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <div className="section full-width roi-calculator-container">
                <ROICalculator
                    showTitle={true}
                    showDescription={true}
                    showCTAs={true}
                />
                <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>

            <div className="faq-container">
                <Typography
                    className="faq-header"
                    variant="h3"
                    component="h2"
                    gutterBottom
                >
                    How does the ROI Calculator work?
                </Typography>

                <Accordion className="faq-accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="faq-1-content"
                    >
                        <Typography variant="h6">
                            How do I calculate savings using this tool?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            The ROI Calculator estimates the time saved and
                            productivity benefits for your team by using
                            Toggles. Simply input the number of team members,
                            their average salary, and the time saved each week
                            by using Toggles, and the tool will calculate the
                            projected annual ROI and savings.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="faq-accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="faq-2-content"
                    >
                        <Typography variant="h6">
                            What factors affect the ROI calculation?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component="div">
                            The ROI depends on:
                            <ul>
                                <li>
                                    <strong>Number of team members</strong>:
                                    More users typically result in higher
                                    overall savings.
                                </li>
                                <li>
                                    <strong>
                                        Average salary of each team member
                                    </strong>
                                    : The higher the salary, the greater the
                                    potential cost savings.
                                </li>
                                <li>
                                    <strong>Hours saved per week</strong>: Based
                                    on how much time your team saves by
                                    automating repetitive emails.
                                </li>
                            </ul>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="faq-accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="faq-3-content"
                    >
                        <Typography variant="h6">
                            How is "Hours Saved" determined?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            The "Hours Saved" is based on the average time your
                            team saves by automating repetitive email tasks.
                            Toggles customers report saving anywhere from 1 to 8
                            hours per week per user, depending on how many
                            emails they send and the complexity of their tasks.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="faq-accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="faq-4-content"
                    >
                        <Typography variant="h6">
                            Can I adjust the number of users and see the impact
                            on ROI?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Yes! The ROI Calculator allows you to adjust the
                            number of users and see how the ROI changes. More
                            users and more hours saved can significantly
                            increase your overall savings.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="faq-accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="faq-5-content"
                    >
                        <Typography variant="h6">
                            What do the "Full-Time Employees equivalent" and
                            "Projected Hours Saved" numbers mean?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component="div">
                            <ul>
                                <li>
                                    <strong>
                                        Full-Time Employees equivalent
                                    </strong>
                                    : This represents how many full-time
                                    employees’ worth of hours your team is
                                    saving annually with Toggles.
                                </li>
                                <li>
                                    <strong>
                                        Projected Hours Saved per Year
                                    </strong>
                                    : This shows the total hours saved across
                                    your team over the course of the year.
                                </li>
                            </ul>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="faq-accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="faq-6-content"
                    >
                        <Typography variant="h6">
                            What is the annual cost of Toggles based on?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            The calculator uses the annual cost of Toggles based
                            on your subscription plan. The default calculation
                            assumes a $180 per user per year cost, which can
                            vary depending on your subscription details.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="faq-accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="faq-7-content"
                    >
                        <Typography variant="h6">
                            Can I change the input values to suit my team’s
                            needs?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Yes, you can adjust the sliders for each of the
                            fields, such as the number of team members, average
                            salary, and hours saved per week. This gives you a
                            customized ROI calculation based on your team’s
                            specific scenario.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="faq-accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="faq-9-content"
                    >
                        <Typography variant="h6">
                            How accurate is the ROI Calculator?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            The ROI Calculator provides an estimate based on
                            data from our customers. While it may not be 100%
                            precise for every team, it gives a good
                            approximation of the time saved and ROI Toggles can
                            deliver.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="faq-accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="faq-10-content"
                    >
                        <Typography variant="h6">
                            Can I get a demo of Toggles before purchasing?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Absolutely! If you’d like a personalized
                            demonstration of Toggles in action, simply{" "}
                            <strong>
                                <a href="/request-demo" title="Link to request a demo of Toggles email productivity tool">Request a Demo</a>
                            </strong>{" "}
                            and one of our team members will walk you through
                            the features and answer any questions you may have.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>

            {/* <div className="split-cta full-width light">
                <Container maxWidth="lg">
                    <Grid container>
                        <Grid
                            xs={12}
                            md={8}
                            order={{ xs: 2, md: 1 }}
                            className="text-content"
                        >
                            <Typography
                                className="text-title mb-30"
                                variant="h3"
                            >
                                Ready to See the Impact on Your Team's
                                Productivity?
                            </Typography>
                            <Typography
                                className="text-description"
                                variant="body1"
                            >
                                Unlock your team's potential and start saving
                                valuable time with Toggles today. Start your
                                free trial and experience the benefits of
                                Toggles for Outlook firsthand.
                            </Typography>
                            <div className="cta-buttons">
                                <Button
                                    className="cta-button"
                                    variant="outlined"
                                    size="large"
                                    href="https://hub.togglesforemail.com/signup?type=business"
                                >
                                    Get started free
                                </Button>
                                <Button
                                    className="cta-button demo-request"
                                    variant="outlined"
                                    size="large"
                                    href="/request-demo"
                                >
                                    Get a demo
                                </Button>
                            </div>
                        </Grid>
                        <Grid
                            xs={12}
                            md={4}
                            order={{ xs: 1, md: 2 }}
                            className="graphic-container"
                        >
                            <img
                                src={solutionsCtaGraphic}
                                alt="Toggles for Outlook - Human Resources Graphic"
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div> */}

            <div className="section full-width cta-curved">
                <Typography
                    className="section-title"
                    variant="h2"
                    align="center"
                >
                    Boost productivity one email at a time
                </Typography>
                <div className="cta-buttons">
                    <Button
                        className="cta-btn contained"
                        variant="outlined"
                        size="large"
                        href="https://hub.togglesforemail.com/signup?type=business"
                    >
                        Get started
                    </Button>
                    <Button
                        className="cta-btn outlined"
                        variant="outlined"
                        size="large"
                        href="/request-demo"
                    >
                        Talk to sales
                    </Button>
                </div>
            </div>
        </Container>
    );
}

function Metadata() {
    return (
        <Helmet>
            <title>
                Toggles | ROI Calculator for Email Productivity and Team Savings
            </title>
            <meta
                name="description"
                content="Discover how much your team can save with Toggles for Outlook. Use our ROI calculator to estimate your annual savings, projected productivity benefits, and time saved. Perfect for Customer Service, HR, Sales, and Financial Services teams. Calculate your ROI now!"
            />
            <meta
                itemProp="name"
                content="Toggles | ROI Calculator for Email Productivity and Team Savings"
            />
            <meta
                itemProp="description"
                content="Discover how much your team can save with Toggles for Outlook. Use our ROI calculator to estimate your annual savings, projected productivity benefits, and time saved. Perfect for Customer Service, HR, Sales, and Financial Services teams. Calculate your ROI now!"
            />
            <meta
                itemProp="image"
                content="http://www.gettoggles.com/graphics/meta-fb-roi-calc-image.jpg"
            />
            <meta
                property="og:url"
                content="https://www.gettoggles.com/productivity-calculator"
            />
            <meta property="og:type" content="website" />
            <meta
                property="og:title"
                content="Toggles | ROI Calculator for Email Productivity and Team Savings"
            />
            <meta
                property="og:description"
                content="Discover how much your team can save with Toggles for Outlook. Use our ROI calculator to estimate your annual savings, projected productivity benefits, and time saved. Perfect for Customer Service, HR, Sales, and Financial Services teams. Calculate your ROI now!"
            />
            <meta
                property="og:image"
                content="https://www.gettoggles.com/graphics/meta-fb-roi-calc-image.jpg"
            />
            <meta name="twitter:site" content="@togglesforemail" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="twitter:title"
                content="Toggles | ROI Calculator for Email Productivity and Team Savings"
            />
            <meta
                name="twitter:description"
                content="Discover how much your team can save with Toggles for Outlook. Use our ROI calculator to estimate your annual savings, projected productivity benefits, and time saved. Perfect for Customer Service, HR, Sales, and Financial Services teams. Calculate your ROI now!"
            />
            <meta
                name="twitter:image"
                content="https://www.gettoggles.com/graphics/meta-twitter-roi-calc-image.jpg"
            />
            <link
                rel="canonical"
                href="https://www.gettoggles.com/productivity-calculator"
            />

            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "WebApplication",
                        "name": "Toggles Productivity Calculator",
                        "url": "https://www.gettoggles.com/productivity-calculator",
                        "description": "Estimate how much time and money your team can save by using Toggles for Outlook to streamline email workflows and automate repetitive tasks.",
                        "applicationCategory": "BusinessApplication",
                        "operatingSystem": "All",
                        "browserRequirements": "Requires JavaScript-enabled browser",
                        "offers": {
                            "@type": "Offer",
                            "price": "0.00",
                            "priceCurrency": "USD",
                            "availability": "https://schema.org/InStock"
                        },
                        "featureList": [
                            "Estimate time saved with automated email workflows",
                            "Calculate cost savings for teams of different sizes",
                            "Interactive sliders to adjust team size and email volume",
                            "Visual graphs to illustrate productivity improvements"
                        ],
                        "provider": {
                            "@type": "Organization",
                            "name": "Toggles",
                            "url": "https://www.gettoggles.com"
                        }
                    }
                `}
            </script>
        </Helmet>
    );
}
