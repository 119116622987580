import React, { useState } from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import togglesIcon from "../../assets/logo32.png";
import outlookIcon from "../../assets/outlook-icon.png";
import "./styles.scss";

const otherFeatures = [
    "Microsoft Outlook support",
    "Google Mail support (Soon)",
    "Tutorial access",
    "Knowledge Base access",
];

function StandardPriceCard({ showButton = true }) {
    const [featuresOpen, setFeaturesOpen] = useState(false);

    return (
        <div className="price-card-container">
            <Card className="price-card" variant="outlined">
                <CardContent className="card-content">
                    <div className="card-title">
                        <img src={togglesIcon} alt="Toggles Logo" />
                        <Typography
                            className="title-text"
                            variant="h5"
                            component="div"
                        >
                            STARTER
                        </Typography>
                    </div>
                    <Typography
                        className="card-description"
                        color="text.secondary"
                        gutterBottom
                    >
                        A useful set of productivity tools, but with
                        limitations, to enhance email productivity for just
                        about anyone
                    </Typography>
                    <Typography className="card-price" variant="h4">
                        <span className="number">Free</span>
                    </Typography>
                    <div className="card-features">
                        <div className="feature">
                            <CheckBoxOutlinedIcon color="primary" />
                            <Typography
                                className="feature-text"
                                variant="subtitle2"
                                component="div"
                            >
                                Limit of 3 Rules
                            </Typography>
                        </div>
                        <div className="feature">
                            <CheckBoxOutlinedIcon color="primary" />
                            <Typography
                                className="feature-text"
                                variant="subtitle2"
                                component="div"
                            >
                                Limited daily usage
                            </Typography>
                        </div>
                        <div className="feature">
                            <CheckBoxOutlinedIcon color="primary" />
                            <Typography
                                className="feature-text"
                                variant="subtitle2"
                                component="div"
                            >
                                Basic rule logic
                            </Typography>
                        </div>
                        <div className="feature">
                            <CheckBoxOutlinedIcon color="primary" />
                            <Typography
                                className="feature-text"
                                variant="subtitle2"
                                component="div"
                            >
                                Single action rules only
                            </Typography>
                        </div>
                        <div className="feature">
                            <CheckBoxOutlinedIcon color="primary" />
                            <Typography
                                className="feature-text"
                                variant="subtitle2"
                                component="div"
                            >
                                Self-service support
                            </Typography>
                        </div>
                    </div>
                </CardContent>
                {showButton && (
                    <CardActions className="price-card-actions">
                        <Button fullWidth variant="contained" size="large">
                            Get Toggles
                        </Button>
                    </CardActions>
                )}

                <Container
                    className="all-features"
                    sx={{ display: { xs: "block", md: "none" } }}
                >
                    <Button
                        className="toggle-all-features"
                        onClick={() => setFeaturesOpen((prev) => !prev)}
                        fullWidth
                        size="small"
                    >
                        All Features{" "}
                        {featuresOpen ? (
                            <ArrowDropUpIcon />
                        ) : (
                            <ArrowDropDownIcon />
                        )}
                    </Button>

                    {featuresOpen && (
                        <div className="card-features all">
                            {otherFeatures.map((feature, idx) => (
                                <div
                                    className="feature"
                                    key={`other-feature-${idx}`}
                                >
                                    <CheckBoxOutlinedIcon color="primary" />
                                    <Typography
                                        className="feature-text"
                                        variant="subtitle2"
                                        component="div"
                                    >
                                        {feature}
                                    </Typography>
                                </div>
                            ))}
                        </div>
                    )}
                </Container>
            </Card>
            <Button
                className="price-start-button free"
                variant="outlined"
                fullWidth
                href="https://hub.togglesforemail.com/signup?type=starter"
            >
                Get Started
            </Button>
        </div>
    );
}

export default StandardPriceCard;
