import React from "react";
import { Helmet } from "react-helmet-async";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import Demo from "../../components/Demo";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import CelebrationIcon from "@mui/icons-material/Celebration";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import templatesFeatureGraphic from "../../assets/bp-templates.png";
import signaturesFeatureGraphic from "../../assets/bp-signatures.png";
import oneClickToggle from "../../assets/bp-one-click3.png";
import solutionsCtaGraphic from "../../assets/solutions-sales-cta.png";
import salesHeroGraphic from "../../assets/sales-hero.png";
import ROICalculator from "../../components/ROICalculator";

import "./solutions.scss";

export default function Sales() {
    return (
        <Container maxWidth="lg">
            <Metadata />

            {/* HERO */}
            <div className="solution-hero split-section">
                <Grid container>
                    <Grid xs={12} lg={5} className="text-content">
                        <Typography className="text-title mb-30" variant="h1">
                            Accelerate your sales team's communication
                        </Typography>
                        <Typography
                            className="text-description"
                            variant="body1"
                        >
                            In Sales, every second counts. Toggles helps sales
                            teams automate prospect outreach, manage follow-ups,
                            and streamline client communications. Save time,
                            boost productivity, and close more deals with
                            consistent, professional emails.
                        </Typography>
                        <div className="cta-buttons">
                            <Button
                                className="cta-button"
                                variant="outlined"
                                size="large"
                                href="https://hub.togglesforemail.com/signup?type=business"
                            >
                                Get started free
                            </Button>
                            <Button
                                className="cta-button demo-request"
                                variant="outlined"
                                size="large"
                                href="/request-demo"
                            >
                                Get a demo
                            </Button>
                        </div>
                    </Grid>
                    <Grid xs={12} lg={7} className="graphic-container">
                        <img
                            src={salesHeroGraphic}
                            alt="Human Resources Hero"
                        />
                    </Grid>
                </Grid>
            </div>

            <div className="solution-demo full-width blue-dark section">
                <Container maxWidth="lg">
                    <Typography
                        className="section-title fwidth"
                        variant="h2"
                        align="center"
                    >
                        Explore time-saving workflows for Sales teams
                    </Typography>

                    <Demo
                        showTypeOptions={false}
                        userTypes={[
                            {
                                name: "sales",
                                icon: (
                                    <MonetizationOnOutlinedIcon className="audience-item-icon" />
                                ),
                            },
                        ]}
                    />
                </Container>
            </div>

            <div className="section full-width solutions-roi-calculator">
                <ROICalculator />
            </div>

            <div className="section full-width blue-dark solution-painpoints">
                <Container maxWidth="xl">
                    <Grid spacing={4} container>
                        <Grid className="painpoint-box" xs={12} md={4}>
                            <div className="painpoint-graphic">
                                <CelebrationIcon className="painpoint-icon" />
                            </div>
                            <Typography
                                className="painpoint-title"
                                variant="h3"
                                align="center"
                            >
                                Reduce Burnout
                            </Typography>
                            <Typography
                                className="painpoint-description"
                                variant="subtitle1"
                                align="center"
                            >
                                Spend less time drafting emails and more time
                                connecting with prospects.
                            </Typography>
                        </Grid>
                        <Grid className="painpoint-box" xs={12} md={4}>
                            <div className="painpoint-graphic">
                                <SettingsSuggestIcon className="painpoint-icon" />
                            </div>
                            <Typography
                                className="painpoint-title"
                                variant="h3"
                                align="center"
                            >
                                Increase Productivity
                            </Typography>
                            <Typography
                                className="painpoint-description"
                                variant="subtitle1"
                                align="center"
                            >
                                Communicate effectively with leads in a fraction
                                of the time using streamlined workflows.
                            </Typography>
                        </Grid>
                        <Grid className="painpoint-box" xs={12} md={4}>
                            <div className="painpoint-graphic">
                                <Diversity2Icon className="painpoint-icon" />
                            </div>
                            <Typography
                                className="painpoint-title"
                                variant="h3"
                                align="center"
                            >
                                Always On-Brand
                            </Typography>
                            <Typography
                                className="painpoint-description"
                                variant="subtitle1"
                                align="center"
                            >
                                Ensure every email sent is professional,
                                consistent, and aligned with your sales
                                strategy.
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <div className="split-cta full-width light">
                <Container maxWidth="lg">
                    <Grid container>
                        <Grid
                            xs={12}
                            md={8}
                            order={{ xs: 2, md: 1 }}
                            className="text-content"
                        >
                            <Typography
                                className="text-title mb-30"
                                variant="h3"
                            >
                                Transform your sales team's communication
                            </Typography>
                            <Typography
                                className="text-description"
                                variant="body1"
                            >
                                In Sales, speed and precision are everything.
                                Toggles for Outlook empowers your team to
                                automate repetitive tasks, streamline client
                                follow-ups, and save time. Focus on closing
                                deals, not crafting emails.
                            </Typography>
                            <div className="cta-buttons">
                                <Button
                                    className="cta-button"
                                    variant="outlined"
                                    size="large"
                                    href="https://hub.togglesforemail.com/signup?type=business"
                                >
                                    Get started free
                                </Button>
                                <Button
                                    className="cta-button demo-request"
                                    variant="outlined"
                                    size="large"
                                    href="/request-demo"
                                >
                                    Get a demo
                                </Button>
                            </div>
                        </Grid>
                        <Grid
                            xs={12}
                            md={4}
                            order={{ xs: 1, md: 2 }}
                            className="graphic-container"
                        >
                            <img
                                src={solutionsCtaGraphic}
                                alt="Toggles for Outlook - Human Resources Graphic"
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <div className="section feature">
                <Container maxWidth="xl">
                    <Grid container spacing={3}>
                        <Grid xs={12} md={6} className="text-content">
                            <Typography className="section-title" variant="h2">
                                Automate prospect outreach with ease
                            </Typography>
                            <Typography variant="h6" className="mb-30">
                                Build workflows that create personalized
                                outreach emails in one click. Let your team
                                focus on nurturing leads while Toggles ensures
                                consistency and efficiency.
                            </Typography>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <img
                                className="feature-img"
                                src={oneClickToggle}
                                alt="Workflows enabled in a single click"
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="section feature">
                <Container maxWidth="xl">
                    <Grid container spacing={3}>
                        <Grid xs={12} md={6} order={{ xs: 2, md: 1 }}>
                            <img
                                className="feature-img"
                                src={templatesFeatureGraphic}
                                alt="Shared email templates for teams"
                            />
                        </Grid>
                        <Grid
                            xs={12}
                            md={6}
                            order={{ xs: 1, md: 2 }}
                            className="text-content"
                        >
                            <Typography className="section-title" variant="h2">
                                Consistent messaging across the pipeline
                            </Typography>
                            <Typography variant="h6">
                                Create a shared library of sales email templates
                                to standardize communication. Ensure every
                                message is on-brand, accurate, and tailored to
                                your team's needs.
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="section feature">
                <Container maxWidth="xl">
                    <Grid container spacing={3}>
                        <Grid xs={12} md={6} className="text-content">
                            <Typography className="section-title" variant="h2">
                                Polished and professional signatures
                            </Typography>
                            <Typography variant="h6">
                                Manage team-wide email signatures effortlessly
                                to maintain professionalism. Keep your sales
                                emails consistent and aligned with your brand
                                identity.
                            </Typography>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <img
                                className="feature-img"
                                src={signaturesFeatureGraphic}
                                alt="Shared email signatures for teams"
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <div className="section full-width cta-curved">
                <Typography
                    className="section-title"
                    variant="h2"
                    align="center"
                >
                    Empower your sales team to sell more, type less.
                </Typography>
                <div className="cta-buttons">
                    <Button
                        className="cta-btn contained"
                        variant="outlined"
                        size="large"
                        href="https://hub.togglesforemail.com/signup?type=business"
                    >
                        Get started
                    </Button>
                    <Button
                        className="cta-btn outlined"
                        variant="outlined"
                        size="large"
                        href="/request-demo"
                    >
                        Talk to sales
                    </Button>
                </div>
            </div>
        </Container>
    );
}

function Metadata() {
    return (
        <Helmet>
            <title>Toggles | Email Automation for Sales</title>
            <meta
                name="description"
                content="Empower sales teams with Toggles for Outlook. Automate prospecting emails, follow-ups, and meeting scheduling to save time and close more deals. Try our customizable Outlook email templates for teams today!"
            />
            <meta
                itemProp="name"
                content="Toggles | Email Automation for Sales"
            />
            <meta
                itemProp="description"
                content="Empower sales teams with Toggles for Outlook. Automate prospecting emails, follow-ups, and meeting scheduling to save time and close more deals. Try our customizable Outlook email templates for teams today!"
            />
            <meta
                itemProp="image"
                content="http://www.gettoggles.com/graphics/meta-fb-image-1200x630.jpg"
            />
            <meta
                property="og:url"
                content="https://www.gettoggles.com/solutions/sales"
            />
            <meta property="og:type" content="website" />
            <meta
                property="og:title"
                content="Toggles | Email Automation for Sales"
            />
            <meta
                property="og:description"
                content="Empower sales teams with Toggles for Outlook. Automate prospecting emails, follow-ups, and meeting scheduling to save time and close more deals. Try our customizable Outlook email templates for teams today!"
            />
            <meta
                property="og:image"
                content="https://www.gettoggles.com/graphics/meta-fb-image-1200x630.jpg"
            />
            <meta name="twitter:site" content="@togglesforemail" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="twitter:title"
                content="Toggles | Email Automation for Sales"
            />
            <meta
                name="twitter:description"
                content="Empower sales teams with Toggles for Outlook. Automate prospecting emails, follow-ups, and meeting scheduling to save time and close more deals. Try our customizable Outlook email templates for teams today!"
            />
            <meta
                name="twitter:image"
                content="https://www.gettoggles.com/graphics/meta-twitter-image-1600x900.jpg"
            />
            <link
                rel="canonical"
                href="https://www.gettoggles.com/solutions/sales"
            />
            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "url": "https://www.gettoggles.com/solutions/sales",
                        "name": "Toggles for Sales Teams",
                        "description": "Empower your sales team with streamlined email workflows, templates, and automation using Toggles for Outlook.",
                        "about": {
                            "@type": "Thing",
                            "name": "Sales Solutions",
                            "description": "Tools designed to help sales teams improve communication, personalize outreach, and close deals faster."
                        },
                        "publisher": {
                            "@type": "Organization",
                            "name": "Toggles for Outlook",
                            "url": "https://www.gettoggles.com",
                            "logo": {
                            "@type": "ImageObject",
                            "url": "https://www.gettoggles.com/logo.png",
                            "width": 600,
                            "height": 60
                            },
                            "contactPoint": {
                            "@type": "ContactPoint",
                            "email": "support@gettoggles.com",
                            "contactType": "Customer Support",
                            "url": "https://www.gettoggles.com/contact"
                            }
                        },
                        "audience": {
                            "@type": "Audience",
                            "audienceType": "Sales Teams",
                            "description": "For sales professionals seeking to enhance productivity and improve deal-closing efficiency."
                        },
                        "isPartOf": {
                            "@type": "WebSite",
                            "name": "Toggles for Outlook",
                            "url": "https://www.gettoggles.com"
                        },
                        "potentialAction": {
                            "@type": "ReadAction",
                            "target": "https://www.gettoggles.com/solutions/sales"
                        }
                    }
                `}
            </script>
        </Helmet>
    );
}
