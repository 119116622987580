import React from "react";
import { Helmet } from "react-helmet-async";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TutorialVideo from "../components/TutorialVideo/TutorialVideo";

import "./Support.scss";

const SUPPORT_TUTORIALS = [
    // {
    //     title: "How to: Install Toggles for Outlook",
    //     description:
    //         "This is a placeholder description that needs to be completed in the near future. There will be a solid description to this tutorial soon!",
    //     embedId: "3mvO8FNGoYE",
    // },
    {
        title: "How to: Activate Outlook Add-in",
        description:
            "Tutorial video on how to create a new Toggles account and activate the Toggles for Outlook Add-in.",
        embedId: "0WsdTK0XKpI",
    },
    {
        title: "How to: Launch Outlook Add-in",
        description:
            "Tutorial video on how to launch the Toggles for Outlook Add-in.",
        embedId: "zgvR3lmeD6w",
    },
    {
        title: "How to: Use Outlook Add-in Menu Bar",
        description:
            "Tutorial video on how to use the Menu Bar within the Toggles for Outlook Add-in.",
        embedId: "5tKJnFscElc",
    },
    {
        title: "How to: Create & Manage Rules",
        description:
            "Tutorial video on how to create and manage rules in the Toggles Hub.",
        embedId: "QNx5SkZs4OQ",
    },
    {
        title: "How to: Use Rules",
        description:
            "Tutorial video on how to use rules in the Toggles for Outlook Add-in.",
        embedId: "qSfd2gJGF1U",
    },
    {
        title: "How to: Create & Manage Templates",
        description:
            "Tutorial video on how to create and manage templates with variables in the Toggles Hub.",
        embedId: "-ZGSLRscs54",
    },
    {
        title: "How to: Use Templates",
        description:
            "Tutorial video on how to use templates in the Toggles for Outlook Add-in.",
        embedId: "US9chN1tOVw",
    },
    {
        title: "How to: Create & Manage Signatures",
        description:
            "Tutorial video on how to create and manage signatures in the Toggles Hub.",
        embedId: "RdYOxbNgcBY",
    },
    {
        title: "How to: Use Signatures",
        description:
            "Tutorial video on how to use signatures in the Toggles for Outlook Add-in.",
        embedId: "QesZnwvAi0M",
    },
    {
        title: "How to: Use ME Variables",
        description:
            "Tutorial video on how to use ME variables within templates in the Toggles for Outlook Add-in.",
        embedId: "RryiP8pLZNs",
    },
    // {
    //     title: "How to: Upgrade to Premium Plan",
    //     description:
    //         "Tutorial video on how to upgrade to the Premium Plan from the Toggles for Outlook Add-in.",
    //     embedId: "EJCJjJxxkE0",
    // },
    {
        title: "How to: Manage Subscription & Billing",
        description:
            "Tutorial video on how to manage your subscription and billing information from the Toggles Hub.",
        embedId: "CQW2TQdLRAA",
    },
];

const SUPPORT_FAQ = [
    {
        question: "How do I get started with Toggles?",
        answer: (
            <div className="answer">
                <Typography variant="body1" gutterBottom>
                    Welcome to Toggles! We're excited to have you on board. To
                    get started, follow these steps:
                </Typography>
                <ol>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            <strong>Install Toggles for Outlook:</strong>{" "}
                            Download the Toggles add-in for Outlook from the{" "}
                            <Link
                                href="https://appsource.microsoft.com/en-us/product/office/WA200006828"
                                underline="none"
                                target="_blank"
                                title="Install the Toggles for Outlook Add-in from the Microsoft AppSource store"
                            >
                                Microsoft AppSource store
                            </Link>
                            .
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            <strong>Activate Outlook Add-in:</strong> Once
                            installed, open Outlook and activate the Toggles
                            add-in.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            <strong>Manage rules:</strong> Create and manage
                            your rules to automate your email workflow.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            <strong>Use rules:</strong> Enable your rules to
                            carry out your workflows when composing any email.
                        </Typography>
                    </li>
                </ol>
            </div>
        ),
    },
    {
        question: "What email clients are supported?",
        answer: (
            <div className="answer">
                <Typography variant="body1" gutterBottom>
                    Toggles is currently only available for Microsoft Outlook
                    (browser and desktop clients). You can install the Toggles
                    for Outlook add-in from the{" "}
                    <Link
                        href="https://appsource.microsoft.com/en-us/product/office/WA200006828"
                        underline="none"
                        target="_blank"
                        title="Learn more about Toggles for Outlook on the Microsoft AppSource store"
                    >
                        Microsoft AppSource store
                    </Link>
                    .
                </Typography>
                <Typography variant="body1">
                    We are working on expanding to Google Mail in the future.
                    Stay tuned for updates!
                </Typography>
            </div>
        ),
    },
    {
        question:
            "I'm not seeing rules, templates, or signatures I created in a different client. How do I fix this?",
        answer: (
            <div className="answer">
                <Typography variant="body1">
                    Rules, templates, and signatures are stored on your account
                    and available to all clients as long as you are logged in.
                </Typography>
                <Typography variant="body1">
                    If you are not seeing any of these that you created in a
                    different client, use the dropdown menu within the add-in
                    and click the "Refresh Account Data" button.
                </Typography>
            </div>
        ),
    },
    {
        question:
            "What is the difference between the Starter Plan and Premium Plan?",
        answer: (
            <div className="answer">
                <Typography variant="body1">
                    The Starter Plan is a free plan that includes basic features
                    to get you started with Toggles. The Premium Plan is a paid
                    plan that includes advanced features to help you take your
                    email productivity to the next level. For more information,
                    visit our{" "}
                    <Link
                        href="/pricing"
                        underline="none"
                        title="Compare Pricing Plans for Toggles for Outlook"
                    >
                        pricing
                    </Link>{" "}
                    page.
                </Typography>
            </div>
        ),
    },
    {
        question:
            "Can I upgrade from the Starter Plan to the Premium Plan at any time?",
        answer: (
            <div className="answer">
                <Typography variant="body1">
                    Yes! You can upgrade to the Premium Plan at any time. Visit
                    our{" "}
                    <Link
                        href="/pricing"
                        underline="none"
                        title="Learn more about Toggles Premium Plus plan"
                    >
                        pricing
                    </Link>{" "}
                    page to learn more about the Premium Plan. You can upgrade
                    to the Premium Plan from within the Toggles add-in.
                </Typography>
            </div>
        ),
    },
    {
        question:
            "Can I downgrade from the Premium Plan to the Starter Plan at any time?",
        answer: (
            <div className="answer">
                <Typography variant="body1">
                    Yes! You can downgrade to the Starter Plan at any time.
                    Visit our{" "}
                    <Link
                        href="/pricing"
                        underline="none"
                        title="See Toggles email productivity tool pricing plans"
                    >
                        pricing
                    </Link>{" "}
                    page to learn more about the Starter Plan. You can downgrade
                    to the Starter Plan from within the Toggles add-in.
                </Typography>
            </div>
        ),
    },
    {
        question: "Do you offer a discounts on an annual plan?",
        answer: (
            <div className="answer">
                <Typography variant="body1">
                    Yes! We offer both monthly and annual Premium and Business
                    Pro plans. The annual plan is roughly a 20% discount
                    compared to the monthly plan.
                </Typography>
            </div>
        ),
    },
    {
        question: "How do I signup for a Business Pro Plan?",
        answer: (
            <div className="answer">
                <Typography variant="body1">
                    To sign up for the Business Pro Plan, you can create your
                    account{" "}
                    <Link
                        href="https://hub.togglesforemail.com/signup?type=business"
                        target="_blank"
                        underline="none"
                        title="Sign up for the Toggles Business Pro Plan"
                    >
                        here
                    </Link>{" "}
                    or contact us at{" "}
                    <Link
                        href="mailto:sales@gettoggles.com"
                        target="_blank"
                        underline="none"
                        title="Contact Toggles sales team"
                    >
                        sales@gettoggles.com
                    </Link>{" "}
                    to learn more.
                </Typography>
            </div>
        ),
    },
    {
        question: "How do I invite my team to Toggles?",
        answer: (
            <div className="answer">
                <Typography variant="body1">
                    As a Business Pro account owner or admin, you can invite
                    your team to Toggles by logging into the Hub and navigating
                    to the "Users" page. From there, you can invite your team
                    members to join your organization.
                </Typography>
                <Typography variant="body1">
                    An email invitation will be sent to your team members to
                    create a Toggles account. Once they have created their
                    account, they will be automatically joined to your
                    organization.
                </Typography>
                <Typography variant="body1">
                    After they have an account, they can install the Toggles
                    add-in for Outlook (or it can be deployed to their mailbox
                    by an administrator) and start using Toggles.
                </Typography>
            </div>
        ),
    },
    {
        question: "How do I cancel my Premium or Business Pro subscription?",
        answer: (
            <div className="answer">
                <Typography variant="body1" gutterBottom>
                    You can cancel your Premium or Business Pro subscription at
                    any time. Within the Toggles add-in, navigate to the "Manage
                    Subscription" menu item, which will open your Stripe portal.
                    From there, you can cancel your subscription.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Note that you will still have access to the Premium or
                    Business Pro features until the end of your current billing
                    period.
                </Typography>
            </div>
        ),
    },
    {
        question: "Do you offer refunds?",
        answer: (
            <div className="answer">
                <Typography variant="body1" gutterBottom>
                    We do not offer refunds for any unused portion of your
                    subscription. You can cancel your subscription at any time,
                    and you will still have access to the Premium or Business
                    Pro Plan features until the end of your current billing
                    period.
                </Typography>
            </div>
        ),
    },
    {
        question: "How do I get updates on new features and bug fixes?",
        answer: (
            <div className="answer">
                <Typography variant="body1" gutterBottom>
                    You will receive updates on new features and bug fixes
                    directly within the Toggles add-in. We will also send
                    periodic emails to keep you informed.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    You can also{" "}
                    <Link
                        href="https://x.com/togglesforemail"
                        target="_blank"
                        underline="none"
                        title="Follow Toggles on X for Updates"
                    >
                        follow us on X
                    </Link>{" "}
                    (Twitter), where we announce major updates and releases.
                </Typography>
            </div>
        ),
    },
    {
        question:
            "How do I contact support if I'm having an issue with Toggles?",
        answer: (
            <div className="answer">
                <Typography variant="body1" gutterBottom>
                    If you're having an issue with Toggles, please contact us at{" "}
                    <Link
                        href="mailto:support@gettoggles.com"
                        target="_blank"
                        underline="none"
                        title="Contact Toggles support team by email"
                    >
                        support@gettoggles.com
                    </Link>
                    . We're here to help you!
                </Typography>
            </div>
        ),
    },
    {
        question: "How do I request a new feature for Toggles?",
        answer: (
            <div className="answer">
                <Typography variant="body1">
                    We love hearing from our users! If you have a feature
                    request or suggestion, please contact us at{" "}
                    <Link
                        href="mailto:support@gettoggles.com"
                        target="_blank"
                        underline="none"
                        title="Contact Toggles support team by email"
                    >
                        support@gettoggles.com
                    </Link>
                    . We take all feedback into consideration when planning new
                    features and updates.
                </Typography>
            </div>
        ),
    },
];

export default function Support() {
    return (
        <Container maxWidth="lg" className="support-container">
            <Metadata />

            <div className="support-hero">
                <Box className="hero-box">
                    <Typography
                        className="hero-title"
                        variant="h1"
                        component={"h1"}
                        align="center"
                    >
                        You have questions. <br />
                        <span className="animated-color">We have answers.</span>
                    </Typography>

                    <div className="hero-buttons">
                        <Button
                            className="hero-btn"
                            variant="outlined"
                            size="large"
                            href="mailto:support@gettoggles.com"
                            target="_blank"
                        >
                            Contact Support
                        </Button>
                        <Button
                            className="hero-btn"
                            variant="contained"
                            size="large"
                            href="/request-demo"
                        >
                            Book a demo
                        </Button>
                    </div>
                </Box>
            </div>

            <Grid container className="faq-grid" spacing={4}>
                {SUPPORT_FAQ.map((qa, index) => (
                    <Grid
                        className="qa-item"
                        item
                        xs={12}
                        md={6}
                        key={`qa-item-${index}`}
                    >
                        <Typography className="question" variant="h4">
                            {qa.question}
                        </Typography>

                        {qa.answer}
                    </Grid>
                ))}
            </Grid>

            <Typography className="container-title" variant="h2" align="center">
                <span className="animated-color">How-to Tutorials</span>
            </Typography>

            <Grid container className="tutorials-grid" spacing={3}>
                {SUPPORT_TUTORIALS.map((tutorial, index) => (
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={4}
                        key={`tutorial-item-${index}`}
                    >
                        <div className="tutorial-item">
                            <TutorialVideo
                                embedId={tutorial.embedId}
                                title={tutorial.title}
                            />
                            <Container className="content-container">
                                <Typography className="title" variant="h4">
                                    {tutorial.title}
                                </Typography>
                                <Typography
                                    className="description"
                                    variant="body1"
                                >
                                    {tutorial.description}
                                </Typography>
                            </Container>
                        </div>
                    </Grid>
                ))}
            </Grid>

            <div className="section full-width cta-curved">
                <Typography
                    className="section-title"
                    variant="h2"
                    align="center"
                >
                    Choose a better way to work
                </Typography>
                <div className="cta-buttons">
                    <Button
                        className="cta-btn contained"
                        variant="outlined"
                        size="large"
                        href="https://hub.togglesforemail.com/signup?type=premium"
                    >
                        Get Started
                    </Button>
                    <Button
                        className="cta-btn outlined"
                        variant="outlined"
                        size="large"
                        href="mailto:support@gettoggles.com"
                        target="_blank"
                    >
                        Contact Support
                    </Button>
                </div>
            </div>
        </Container>
    );
}

function Metadata() {
    return (
        <Helmet>
            <title>
                Toggles Support & FAQ | Get Help with Email Productivity
            </title>
            <meta
                name="description"
                content="Find answers to common questions and get support for Toggles. Explore our FAQ section for tips, troubleshooting, and guidance on maximizing your email productivity."
            />
            <meta property="og:url" content="https://www.gettoggles.com" />
            <meta property="og:type" content="website" />
            <meta
                property="og:title"
                content="Toggles Support & FAQ | Get Help with Email Productivity"
            />
            <meta
                property="og:description"
                content="Find answers to common questions and get support for Toggles. Explore our FAQ section for tips, troubleshooting, and guidance on maximizing your email productivity."
            />
            <meta
                property="og:image"
                content="https://www.gettoggles.com/graphics/meta-fb-image-1200x630.jpg"
            />
            <meta name="twitter:site" content="@togglesforemail" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="twitter:title"
                content="Toggles Support & FAQ | Get Help with Email Productivity"
            />
            <meta
                name="twitter:description"
                content="Find answers to common questions and get support for Toggles. Explore our FAQ section for tips, troubleshooting, and guidance on maximizing your email productivity."
            />
            <meta
                name="twitter:image"
                content="https://www.gettoggles.com/graphics/meta-twitter-image-1600x900.jpg"
            />
            <link rel="canonical" href="https://www.gettoggles.com/support" />

            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [
                            {
                            "@type": "Question",
                            "name": "How do I get started with Toggles?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Welcome to Toggles! We're excited to have you on board. To get started, follow these steps: 1. Install Toggles for Outlook: Download the Toggles add-in for Outlook from the Microsoft AppSource store. 2. Activate Outlook Add-in: Once installed, open Outlook and activate the Toggles add-in. 3. Manage rules: Create and manage your rules to automate your email workflow. 4. Use rules: Enable your rules to carry out your workflows when composing any email."
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "What email clients are supported?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Toggles is currently only available for Microsoft Outlook (browser and desktop clients). You can install the Toggles for Outlook add-in from the Microsoft AppSource store. We are working on expanding to Google Mail in the future. Stay tuned for updates!"
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "I'm not seeing rules, templates, or signatures I created in a different client. How do I fix this?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Rules, templates, and signatures are stored on your account and available to all clients as long as you are logged in. If you are not seeing any of these that you created in a different client, use the dropdown menu within the add-in and click the 'Refresh Account Data' button."
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "What is the difference between the Starter Plan and Premium Plan?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "The Starter Plan is a free plan that includes basic features to get you started with Toggles. The Premium Plan is a paid plan that includes advanced features to help you take your email productivity to the next level. For more information, visit our pricing page."
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "Can I upgrade from the Starter Plan to the Premium Plan at any time?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Yes! You can upgrade to the Premium Plan at any time. Visit our pricing page to learn more about the Premium Plan. You can upgrade to the Premium Plan from within the Toggles add-in."
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "Can I downgrade from the Premium Plan to the Starter Plan at any time?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Yes! You can downgrade to the Starter Plan at any time. Visit our pricing page to learn more about the Starter Plan. You can downgrade to the Starter Plan from within the Toggles add-in."
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "Do you offer discounts on an annual plan?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Yes! We offer both monthly and annual Premium and Business Pro plans. The annual plan is roughly a 20% discount compared to the monthly plan."
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "How do I signup for a Business Pro Plan?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "To sign up for the Business Pro Plan, you can create your account here or contact us at sales@gettoggles.com to learn more."
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "How do I invite my team to Toggles?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "As a Business Pro account owner or admin, you can invite your team to Toggles by logging into the Hub and navigating to the 'Users' page. From there, you can invite your team members to join your organization. An email invitation will be sent to your team members to create a Toggles account. Once they have created their account, they will be automatically joined to your organization. After they have an account, they can install the Toggles add-in for Outlook (or it can be deployed to their mailbox by an administrator) and start using Toggles."
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "How do I cancel my Premium or Business Pro subscription?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "You can cancel your Premium or Business Pro subscription at any time. Within the Toggles add-in, navigate to the 'Manage Subscription' menu item, which will open your Stripe portal. From there, you can cancel your subscription. Note that you will still have access to the Premium or Business Pro features until the end of your current billing period."
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "Do you offer refunds?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "We do not offer refunds for any unused portion of your subscription. You can cancel your subscription at any time, and you will still have access to the Premium or Business Pro Plan features until the end of your current billing period."
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "How do I get updates on new features and bug fixes?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "You will receive updates on new features and bug fixes directly within the Toggles add-in. We will also send periodic emails to keep you informed. You can also follow us on Twitter (X), where we announce major updates and releases."
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "How do I contact support if I'm having an issue with Toggles?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "If you're having an issue with Toggles, please contact us at support@gettoggles.com. We're here to help you!"
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "How do I request a new feature for Toggles?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "We love hearing from our users! If you have a feature request or suggestion, please contact us at support@gettoggles.com. We take all feedback into consideration."
                            }
                            }
                        ]
                        }
                `}
            </script>
        </Helmet>
    );
}
