import * as React from "react";
import { Helmet } from "react-helmet-async";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import "./NotFound.scss";

export default function NotFound() {
    return (
        <Container maxWidth="lg" className="not-found-container">
            <Metadata />

            <Typography
                className="section-title"
                variant="h2"
                component="h1"
                align="center"
            >
                Oops! This page doesn't exist...
            </Typography>
        </Container>
    );
};

function Metadata() {
    return (
        <Helmet>
            <title>Toggles | Oops! Page Not Found</title>
            <meta
                name="description"
                content="Sorry, the page you're looking for could not be found. Please check the URL for any typos or return to the Toggles homepage to explore our email productivity tools."
            />
            <meta property="og:url" content="https://www.gettoggles.com" />
            <meta property="og:type" content="website" />
            <meta
                property="og:title"
                content="Toggles | Oops! Page Not Found"
            />
            <meta
                property="og:description"
                content="Sorry, the page you're looking for could not be found. Please check the URL for any typos or return to the Toggles homepage to explore our email productivity tools."
            />
            <meta
                property="og:image"
                content="https://www.gettoggles.com/graphics/meta-fb-image-1200x630.jpg"
            />
            <meta name="twitter:site" content="@togglesforemail" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="twitter:title"
                content="Toggles | Oops! Page Not Found"
            />
            <meta
                name="twitter:description"
                content="Sorry, the page you're looking for could not be found. Please check the URL for any typos or return to the Toggles homepage to explore our email productivity tools."
            />
            <meta
                name="twitter:image"
                content="https://www.gettoggles.com/graphics/meta-twitter-image-1600x900.jpg"
            />

            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Page Not Found",
                        "description": "This page does not exist. Use the navigation above to find the content you're looking for or return to the home page."
                    }
                `}
            </script>
        </Helmet>
    );
}
