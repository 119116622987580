import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CheckIcon from "@mui/icons-material/Check";
import FiberNewIcon from "@mui/icons-material/FiberNew";

const rows = [
    {
        name: "Free Trial",
        free: false,
        premium: true,
        business: true,
        isBool: true,
        isNew: true,
    },
    {
        name: "Microsoft Outlook support",
        free: true,
        premium: true,
        business: true,
        isBool: true,
    },
    {
        name: "Google Mail support",
        free: "Coming Soon",
        premium: "Coming Soon",
        business: "Coming Soon",
        isBool: false,
    },
    {
        name: "Number of rules",
        free: "3",
        premium: "Unlimited",
        business: "Unlimited",
        isBool: false,
    },
    {
        name: "Daily usage limits",
        free: true,
        premium: false,
        business: false,
        isBool: true,
    },
    {
        name: "Basic rule logic",
        free: true,
        premium: true,
        business: true,
        isBool: true,
    },
    {
        name: "Advanced rule logic",
        free: false,
        premium: true,
        business: true,
        isBool: true,
    },
    {
        name: "Multiple action rules",
        free: false,
        premium: true,
        business: true,
        isBool: true,
    },
    {
        name: "Email template support",
        free: false,
        premium: true,
        business: true,
        isBool: true,
        isNew: true,
    },
    {
        name: "Email signature support",
        free: false,
        premium: true,
        business: true,
        isBool: true,
        isNew: true,
    },
    {
        name: "Variable types & default values support",
        free: false,
        premium: true,
        business: true,
        isBool: true,
        isNew: true,
    },
    {
        name: "Subject variables support",
        free: false,
        premium: true,
        business: true,
        isBool: true,
        isNew: true,
    },
    {
        name: "Attachment action support",
        free: false,
        premium: true,
        business: true,
        isBool: true,
    },
    {
        name: "Delay Send action support",
        free: true,
        premium: true,
        business: true,
        isBool: true,
    },
    {
        name: "Shared mailbox support",
        free: false,
        premium: false,
        business: true,
        isBool: true,
    },
    {
        name: "User Groups",
        free: false,
        premium: false,
        business: true,
        isBool: true,
    },
    {
        name: "Group Rules",
        free: false,
        premium: false,
        business: true,
        isBool: true,
    },
    {
        name: "Company-wide Rules",
        free: false,
        premium: false,
        business: true,
        isBool: true,
    },
    {
        name: "Tutorial Access",
        free: true,
        premium: true,
        business: true,
        isBool: true,
    },
    // {
    //     name: "Knowledge Base Access",
    //     free: true,
    //     premium: true,
    //     business: true,
    //     isBool: true,
    // },
    {
        name: "Email support",
        free: false,
        premium: true,
        business: true,
        isBool: true,
    },
];

function ComparisonTable() {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell
                            className="table-header"
                            sx={{
                                color: "#000000",
                                fontSize: "1rem",
                            }}
                            align="center"
                        >
                            Starter
                        </TableCell>
                        <TableCell
                            className="table-header"
                            sx={{
                                backgroundColor: "#000000",
                                color: "#ffffff",
                                fontSize: "1rem",
                            }}
                            align="center"
                        >
                            Premium
                        </TableCell>
                        <TableCell
                            className="table-header"
                            sx={{
                                backgroundColor: "#1b568e",
                                color: "#ffffff",
                                fontSize: "1rem",
                            }}
                            align="center"
                        >
                            Business Pro
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell component="th" scope="row">
                                {row.name}
                                {row.isNew && (
                                    <FiberNewIcon
                                        sx={{
                                            color: "#339dff",
                                            fontSize: "1.5rem",
                                            marginLeft: "0.5rem",
                                        }}
                                    />
                                )}
                            </TableCell>
                            <TableCell
                                className="table-cell-value"
                                align="center"
                            >
                                {row.isBool
                                    ? row.free && <CheckIcon />
                                    : row.free}
                            </TableCell>
                            <TableCell
                                className="table-cell-value premium"
                                align="center"
                            >
                                {row.isBool
                                    ? row.premium && <CheckIcon />
                                    : row.premium}
                            </TableCell>
                            <TableCell
                                className="table-cell-value business"
                                align="center"
                            >
                                {row.isBool
                                    ? row.business && <CheckIcon />
                                    : row.business}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default ComparisonTable;
