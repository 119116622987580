// @ts-nocheck
import React, { useState, useEffect, forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="right" ref={ref} {...props} />;
});

import logo from "../../assets/logo-dark_260x139.png";
import "./styles.scss";

const pages = [
    {
        name: "For Teams",
        link: "/teams",
        submenuLabel: "Overview",
        children: [
            {
                name: "Human Resources",
                link: "/solutions/human-resources",
            },
            {
                name: "Customer Success",
                link: "/solutions/customer-success",
            },
            {
                name: "Sales",
                link: "/solutions/sales",
            },
            {
                name: "Finance",
                link: "/solutions/finance",
            },
        ],
    },
    {
        name: "Pricing",
        link: "/pricing",
    },
    {
        name: "Support",
        link: "/support",
    },
];

function NavBar({ trigger }) {
    const [open, setOpen] = useState(false);
    const [menuItemAnchorEl, setMenuItemAnchorEl] = useState(null);
    const itemMenuOpen = Boolean(menuItemAnchorEl);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const toggleOpen = () => {
        setOpen((prev) => !prev);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleItemMenuClick = (event) => {
        setMenuItemAnchorEl(event.currentTarget);
    };

    const handleMenuItemClose = () => {
        setMenuItemAnchorEl(null);
    };

    useEffect(() => {
        if (!isMobile && open) {
            setOpen(false);

        } else {isMobile} {
            setMenuItemAnchorEl(null);
        }
    }, [isMobile, open]);

    return (
        <AppBar
            className={`nav-bar ${trigger && "nav-bar-scrolled"}`}
            position={trigger ? "fixed" : "static"}
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
                        <RouterLink to="/" onClick={handleClose}>
                            <img
                                src={logo}
                                className="nav-logo"
                                alt="Toggles Logo"
                            />
                        </RouterLink>
                    </Box>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "flex", md: "none" },
                        }}
                    >
                        <IconButton
                            size="large"
                            aria-label="nav menu button"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={toggleOpen}
                            color="inherit"
                        >
                            {open ? <CloseIcon /> : <MenuIcon />}
                        </IconButton>
                        <Dialog
                            className={`mobile-nav-menu ${
                                trigger && "scrolled"
                            }`}
                            fullScreen
                            open={open}
                            onClose={handleClose}
                            TransitionComponent={Transition}
                            hideBackdrop={true}
                        >
                            <List>
                                {pages.map((page, idx) => {
                                    return (
                                        <div key={`mobile-menu-item-${idx}`}>
                                            {page.children ? (
                                                <>
                                                    <ListItemButton
                                                        className="mobile-nav-menu-item"
                                                        key={page.name}
                                                        onClick={handleClose}
                                                        component={RouterLink}
                                                        to={page.link}
                                                    >
                                                        <ListItemText>
                                                            {page.name}
                                                        </ListItemText>
                                                    </ListItemButton>
                                                    <List>
                                                        {page.children.map(
                                                            (child, idx) => (
                                                                <ListItemButton
                                                                    className="mobile-nav-menu-item child"
                                                                    key={
                                                                        child.name
                                                                    }
                                                                    onClick={
                                                                        handleClose
                                                                    }
                                                                    component={
                                                                        RouterLink
                                                                    }
                                                                    to={
                                                                        child.link
                                                                    }
                                                                >
                                                                    <ListItemText>
                                                                        {
                                                                            child.name
                                                                        }
                                                                    </ListItemText>
                                                                </ListItemButton>
                                                            )
                                                        )}
                                                    </List>
                                                    <Divider />
                                                </>
                                            ) : (
                                                <>
                                                    <ListItemButton
                                                        className="mobile-nav-menu-item"
                                                        key={page.name}
                                                        onClick={handleClose}
                                                        component={RouterLink}
                                                        to={page.link}
                                                    >
                                                        <ListItemText>
                                                            {page.name}
                                                        </ListItemText>
                                                    </ListItemButton>

                                                    <Divider />
                                                </>
                                            )}
                                        </div>
                                    );
                                })}
                                <div key="mobile-menu-item-login">
                                    <ListItemButton
                                        className="mobile-nav-menu-item"
                                        key="mobile-nav-login-btn"
                                        onClick={handleClose}
                                        href="https://hub.togglesforemail.com/login"
                                    >
                                        <ListItemText>Login</ListItemText>
                                    </ListItemButton>

                                    <Divider />
                                </div>
                                <div key="mobile-menu-item-signup">
                                    <ListItemButton
                                        className="mobile-nav-menu-item"
                                        key="mobile-nav-signup-btn"
                                        onClick={handleClose}
                                        href="https://hub.togglesforemail.com/signup"
                                    >
                                        <ListItemText>
                                            Get Started Free
                                        </ListItemText>
                                    </ListItemButton>
                                </div>
                            </List>
                        </Dialog>
                    </Box>
                    <Box sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}>
                        <RouterLink to="/" onClick={handleClose}>
                            <img
                                src={logo}
                                className="nav-logo"
                                alt="Toggles Logo"
                            />
                        </RouterLink>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "none", md: "flex" },
                        }}
                    >
                        {pages.map((page) => (
                            <div key={page.name}>
                                {page.children ? (
                                    <>
                                        <Button
                                            className="nav-menu-item"
                                            key={`top-menu-item-${page.name}`}
                                            onClick={handleItemMenuClick}
                                        >
                                            {page.name}
                                        </Button>
                                        <Menu
                                            anchorEl={menuItemAnchorEl}
                                            open={itemMenuOpen}
                                            onClose={handleMenuItemClose}
                                            MenuListProps={{
                                                "aria-labelledby":
                                                    "teams-solution-button",
                                            }}
                                        >
                                            <MenuItem
                                                key={`${page.name}-menu-item-overview`}
                                                onClick={handleMenuItemClose}
                                                component={RouterLink}
                                                to={page.link}
                                            >
                                                {page.submenuLabel}
                                            </MenuItem>
                                            {page.children.map((child) => (
                                                <MenuItem
                                                    key={child.name}
                                                    onClick={
                                                        handleMenuItemClose
                                                    }
                                                    component={RouterLink}
                                                    to={child.link}
                                                >
                                                    {child.name}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </>
                                ) : (
                                    <Button
                                        className="nav-menu-item"
                                        key={page.name}
                                        component={RouterLink}
                                        to={page.link}
                                        sx={{
                                            my: 2,
                                            display: "block",
                                        }}
                                    >
                                        {page.name}
                                    </Button>
                                )}
                            </div>
                        ))}
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "none", md: "flex" },
                            justifyContent: "flex-end",
                        }}
                    >
                        <Button
                            className="nav-menu-item"
                            key="nav-menu-login-btn"
                            href="https://hub.togglesforemail.com/login"
                            sx={{
                                my: 2,
                                display: "block",
                            }}
                        >
                            Login
                        </Button>
                        <Button
                            className="nav-menu-item"
                            key="nav-menu-signup-btn"
                            href="https://hub.togglesforemail.com/signup"
                            variant="outlined"
                            sx={{
                                my: 2,
                                display: "block",
                            }}
                        >
                            Get Started Free
                        </Button>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default NavBar;
